import React from "react";
import { Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const { Title } = Typography;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <CenteredContainer>
      <Title>Erreur 404</Title>
      <Button type="primary" onClick={handleRedirect}>
        Retour à l'accueil
      </Button>
    </CenteredContainer>
  );
};

export default NotFound;
