import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomePageBackoffice: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/backoffice/dossiers");
  }, [navigate]);

  return <div></div>;
};

export default HomePageBackoffice;
