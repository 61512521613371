import React from "react";
import styled from "styled-components";
import SimulatorComponent from "../component/simulator/SimulatorComponent";
import Navbar from "../component/navbar/Navbar";

const ContainerPrincipal = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  @media (max-width: 768px) {
    padding: 0 10px;
    height: auto;
  }
`;

const HomePage: React.FC = () => {
  return (
    <div>
      <Navbar />
      <ContainerPrincipal>
        <SimulatorComponent />
      </ContainerPrincipal>
    </div>
  );
};

export default HomePage;
