import { useState } from "react";
import { Button, Form, Input, Typography, message } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../services/users.service";
import { ToValidResetPassword } from "../types/auth.interface";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { checkApiError } from "../apiCheckError";

const { Title, Text } = Typography;

export default function ResetPasswordForm(): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [params] = useSearchParams();
  const resetuuid = params.get("reset_uuid") ?? "";

  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<ToValidResetPassword>();

  const password = watch("password");

  const onSubmit = async (data: ToValidResetPassword) => {
    setIsSubmitting(true);
    const response = await resetPassword(data.password, resetuuid);
    checkApiError(response.respStatus);
    setIsSubmitting(false);
    message.success("Réinitialisation du mot de passe correctement effectuée");
    navigate("/authentification");
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 16px",
      }}
    >
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <div style={{ textAlign: "center", marginBottom: "24px" }}>
          <Title level={3}>Nouveau mot de passe</Title>
          <Text>Vous pouvez maintenant définir votre nouveau mot de passe</Text>
        </div>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Form.Item
            label="Nouveau mot de passe"
            validateStatus={errors.password ? "error" : ""}
            help={errors.password ? errors.password.message : ""}
          >
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input.Password
                  {...field}
                  placeholder="********"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              )}
              rules={{ required: "Le nouveau mot de passe est obligatoire" }}
            />
          </Form.Item>

          <Form.Item
            label="Confirmer le nouveau mot de passe"
            validateStatus={errors.new_password ? "error" : ""}
            help={errors.new_password ? errors.new_password.message : ""}
          >
            <Controller
              name="new_password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input.Password
                  {...field}
                  placeholder="********"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              )}
              rules={{
                required: "La confirmation du mot de passe est obligatoire",
                validate: (value) =>
                  value === password ||
                  "Les mots de passe ne correspondent pas",
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={isSubmitting}
            >
              Réinitialiser mon mot de passe
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
