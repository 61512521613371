import { ShopOutlined } from "@ant-design/icons";
import { Form, Input, Select, Typography } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import AddressInput from "./AddressInput";
import { MAIN_COLOR } from "../../utils";
import { PropertyType } from "../../types/REFolder.interface";

const { Title } = Typography;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .anticon {
    margin-right: 8px;
    font-size: 24px;
  }

  h4 {
    margin: 0;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const IconStyled = styled.div`
  .anticon {
    color: ${MAIN_COLOR};
  }
`;

const options = [
  { label: "Maison", value: PropertyType.House },
  { label: "Appartement", value: PropertyType.Appartment },
  { label: "Travaux", value: PropertyType.RenovationWork },
  { label: "Autres", value: PropertyType.Other },
];

const formatNumber = (value: number) => {
  return value.toLocaleString("fr-FR");
};

const LeftCol: React.FC<{
  control: any;
  defaultValues?: {
    propertyType?: string;
    propertyPrice?: number;
    renovationBudget?: number;
  };
}> = ({ control, defaultValues }) => {
  return (
    <>
      <TitleWithIcon>
        <IconStyled>
          <ShopOutlined />
        </IconStyled>
        <Title level={4}>Détails du bien</Title>
      </TitleWithIcon>
      <FormItem label="Type de bien">
        <Controller
          name="propertyType"
          control={control}
          defaultValue={defaultValues?.propertyType || PropertyType.House}
          render={({ field }) => (
            <Select {...field}>
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormItem>
      <FormItem
        label="Prix du bien"
        rules={[
          {
            required: true,
            message: "Le prix du bien est obligatoire",
          },
        ]}
      >
        <Controller
          name="propertyPrice"
          control={control}
          defaultValue={defaultValues?.propertyPrice || "200000"}
          render={({ field }) => (
            <Input
              type="text"
              {...field}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/\s/g, "");
                if (!isNaN(rawValue as any)) {
                  field.onChange(rawValue);
                }
              }}
              value={field.value ? formatNumber(Number(field.value)) : ""}
              onBlur={(e) => {
                const rawValue = e.target.value.replace(/\s/g, "");
                const value = parseFloat(rawValue);
                field.onChange(value);
                e.target.value = formatNumber(value);
              }}
            />
          )}
        />
      </FormItem>
      <FormItem
        label="Montant des travaux"
        rules={[
          {
            required: true,
            message: "Le montant des travaux est obligatoire",
          },
        ]}
      >
        <Controller
          name="renovationBudget"
          control={control}
          defaultValue={defaultValues?.renovationBudget || "0"}
          render={({ field }) => (
            <Input
              type="text"
              {...field}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/\s/g, "");
                if (!isNaN(rawValue as any)) {
                  field.onChange(rawValue);
                }
              }}
              value={field.value ? formatNumber(Number(field.value)) : ""}
              onBlur={(e) => {
                const rawValue = e.target.value.replace(/\s/g, "");
                const value = parseFloat(rawValue);
                field.onChange(value);
                e.target.value = formatNumber(value);
              }}
            />
          )}
        />
      </FormItem>
      <AddressInput />
    </>
  );
};

export default LeftCol;
