import React from "react";
import { Card, Row, Col } from "antd";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import { FolderDetails } from "../../../types/REFolder.interface";
import { UserType } from "../../../types";
import { MAIN_COLOR } from "../../../utils";

const InfoText = styled.p`
  font-size: 18px;
  margin-bottom: 23px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 18px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const formatNumber = (num: number | undefined) => {
  return num?.toLocaleString("fr-FR");
};

const FolderInformations: React.FC<{
  folder: FolderDetails | null;
  user: UserType | null;
}> = ({ folder, user }) => {
  return (
    <Card
      title={
        <>
          <UserOutlined style={{ color: MAIN_COLOR, marginRight: "8px" }} />
          Informations
        </>
      }
      style={{ width: "100%" }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <InfoText>
            <BoldText>Nom : </BoldText>
            {user?.firstname} {user?.lastname}
          </InfoText>
          <InfoText>
            <BoldText>Email : </BoldText>
            {user?.email}
          </InfoText>
          <InfoText>
            <BoldText>Type de bien : </BoldText>
            {folder?.propertyType}
          </InfoText>
          <InfoText>
            <BoldText>Condition : </BoldText>
            {folder?.condition}
          </InfoText>
          <InfoText>
            <BoldText>Adresse : </BoldText>
            {folder?.address}
          </InfoText>
        </Col>
        <Col xs={24} md={12}>
          <InfoText>
            <BoldText>Prix : </BoldText>
            {formatNumber(folder?.propertyPrice)} €
          </InfoText>
          <InfoText>
            <BoldText>Budget rénovation : </BoldText>
            {formatNumber(folder?.renovationBudget)} €
          </InfoText>
          <InfoText>
            <BoldText>Apport désiré : </BoldText>
            {formatNumber(folder?.desiredContribution)} €
          </InfoText>
          <InfoText>
            <BoldText>Durée du prêt : </BoldText>
            {folder?.loanDuration} ans
          </InfoText>
          <InfoText>
            <BoldText>Scoring : </BoldText>
            {folder?.scoring}
          </InfoText>
        </Col>
      </Row>
    </Card>
  );
};

export default FolderInformations;
