import React, { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { AdminRoles, UserRoles, UserType } from "../types/auth.interface";
import { loginMagicLink } from "../services/users.service";
import { checkApiError } from "../apiCheckError";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("reeadyid"),
  );
  const [user, setUser] = useState<UserType | null>(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!)
      : null,
  );
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(token !== null);
  const [justLoggedIn, setJustLoggedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(
    user?.admin_role === AdminRoles.SuperAdmin,
  );
  const [isBank, setIsBank] = useState<boolean>(user?.role === UserRoles.Bank);

  useEffect(() => {
    const fetchStorage = () => {
      const storedToken = localStorage.getItem("reeadyid");
      const storedUser = localStorage.getItem("user");
      if (storedToken) {
        setToken(storedToken);
        setIsLoggedIn(true);
      }
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setIsAdmin(parsedUser?.admin_role === AdminRoles.SuperAdmin);
        setIsBank(parsedUser?.role === UserRoles.Bank);
      }
    };

    fetchStorage();
    window.addEventListener("storage", fetchStorage);

    return () => {
      window.removeEventListener("storage", fetchStorage);
    };
  }, []);

  const login = (newToken: string, newUser: UserType) => {
    setToken(newToken);
    setUser(newUser);
    setIsLoggedIn(true);
    setIsAdmin(newUser?.admin_role === AdminRoles.SuperAdmin);
    setIsBank(newUser?.role === UserRoles.Bank);
    localStorage.setItem("reeadyid", newToken);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    setIsLoggedIn(false);
    setJustLoggedIn(false);
    setIsAdmin(false);
    setIsBank(false);
    localStorage.removeItem("reeadyid");
    localStorage.removeItem("user");
  };

  const checkValidationCode = async (validationCode: string) => {
    setLoading(true);
    try {
      const response = await loginMagicLink(validationCode);
      checkApiError(response.respStatus);
      if (response.respData) {
        setIsLoggedIn(true);
        setIsBank(true);
      } else {
        setIsLoggedIn(false);
        setIsBank(false);
      }
    } catch (error) {
      console.error("Failed to validate code:", error);
      setIsLoggedIn(false);
      setIsBank(false);
    }
    setLoading(false);
    return isBank;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        justLoggedIn,
        isAdmin,
        isBank,
        token,
        login,
        logout,
        loading,
        checkValidationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
