import React, { useState, useEffect } from "react";
import { Typography, Tabs, Badge, Card, Spin } from "antd";
import { FolderOutlined, ClockCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  HOOVER_COLOR,
  REAL_BACKGROUND_COLOR,
  MAIN_COLOR,
  BANK_ICON_2_COLOR,
  BANK_ICON_3_COLOR,
} from "../../utils";
import { getBankFolders } from "../../services/RealEstateFolder.service";
import { FolderDetails, REFolderStatus } from "../../types/REFolder.interface";
import { useAuth } from "../../authentification/auth.hook";
import { useNavigate } from "react-router-dom";
import { checkApiError } from "../../apiCheckError";
import {
  BANK_TIMER_1_WEEK,
  BANK_TIMER_48_HOURS,
} from "../component/timers/BankTimers";

const { Title } = Typography;
const { TabPane } = Tabs;

const Container = styled.div`
  padding: 20px;
  text-align: center;
  min-height: 100vh;
`;

const LogoTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ant-tabs-tab {
    background-color: ${REAL_BACKGROUND_COLOR};
    color: white;
    border: 1px solid ${REAL_BACKGROUND_COLOR};
    margin: 0 8px;
    padding: 8px 16px;
    border-bottom: 1px solid ${HOOVER_COLOR};
    font-size: 18px;
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
`;

const LargeBadge = styled(Badge)`
  .ant-badge-count {
    font-size: 16px;
    height: 20px;
    min-width: 24px;
  }
`;

const Description = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: white;
`;

const TitleWithTimer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TimerText = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
`;

const calculateRemainingTime = (endTime: Date) => {
  const now = new Date();
  const diffMs = endTime.getTime() - now.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  const remainingMinutes = diffMinutes % 60;
  const remainingHours = diffHours % 24;

  if (diffMs <= 0) return "Temps écoulé";

  return `${diffDays}j ${remainingHours}h ${remainingMinutes}m`;
};

const MyFoldersBank: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("1");
  const [, setFolders] = useState<FolderDetails[]>([]);
  const [waitingForBankAppointment, setWaitingForBankAppointment] = useState<
    FolderDetails[]
  >([]);
  const [waitingForOfferSignature, setWaitingForOfferSignature] = useState<
    FolderDetails[]
  >([]);
  const [waitingForNotaryAppointment, setWaitingForNotaryAppointment] =
    useState<FolderDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFolders = async () => {
      const foldersResponse = await getBankFolders();
      checkApiError(foldersResponse.respStatus);
      const fetchedFolders = foldersResponse.respData;
      setFolders(fetchedFolders);

      if (user) {
        setWaitingForBankAppointment(
          fetchedFolders.filter(
            (folder) =>
              folder.status === REFolderStatus.WaitingForBankAppointment &&
              folder.bidInfos &&
              folder.bidInfos.some(
                (bidInfo) => bidInfo.active && bidInfo.bankUserId === user.id,
              ),
          ),
        );

        setWaitingForOfferSignature(
          fetchedFolders.filter(
            (folder) =>
              folder.status === REFolderStatus.WaitingForOfferSignature &&
              folder.bidInfos &&
              folder.bidInfos.some(
                (bidInfo) => bidInfo.active && bidInfo.bankUserId === user.id,
              ),
          ),
        );

        setWaitingForNotaryAppointment(
          fetchedFolders.filter(
            (folder) =>
              folder.status === REFolderStatus.WaitingForNotaryAppointment &&
              folder.bidInfos &&
              folder.bidInfos.some(
                (bidInfo) => bidInfo.active && bidInfo.bankUserId === user.id,
              ),
          ),
        );
      }
      setLoading(false);
    };

    fetchFolders();
  }, [user]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const getDescription = () => {
    switch (activeTab) {
      case "1":
        return "Dossiers en attente d'un rendez-vous avec la banque";
      case "2":
        return "Dossiers en attente de la signature de l'offre";
      case "3":
        return "Dossiers en attente d'un rendez-vous avec le notaire";
      default:
        return "";
    }
  };

  const handleCardClick = (id: string) => {
    navigate(`/banque/mes-dossiers/${id}`);
  };

  const renderFolderCards = (folders: FolderDetails[]) =>
    folders.map((folder) => {
      const status = folder.status;
      const updatedAt = new Date(folder.updated_at);
      let endTime = updatedAt;

      //Change timers if needed
      switch (status) {
        case REFolderStatus.WaitingForBankAppointment:
          endTime.setHours(updatedAt.getHours() + BANK_TIMER_48_HOURS);
          break;
        case REFolderStatus.WaitingForOfferSignature:
          endTime.setDate(updatedAt.getDate() + BANK_TIMER_1_WEEK);
          break;
        case REFolderStatus.WaitingForNotaryAppointment:
          endTime.setHours(updatedAt.getHours() + BANK_TIMER_48_HOURS);
          break;
        default:
          break;
      }

      const timer = calculateRemainingTime(endTime);

      return (
        <Card
          key={folder.id}
          title={
            <TitleWithTimer>
              <span>{folder.propertyType}</span>
              <TimerText>
                <ClockCircleOutlined style={{ color: MAIN_COLOR }} />
                {timer}
              </TimerText>
            </TitleWithTimer>
          }
          style={{ marginBottom: 16, width: "100%", cursor: "pointer" }}
          onClick={() => handleCardClick(folder.id)}
        >
          <p>
            <strong>Adresse : </strong> {folder.address}
          </p>
          <p>
            <strong>Prix : </strong> {folder.propertyPrice} €
          </p>
          <p>
            <strong>Condition : </strong> {folder.condition}
          </p>
          <p>
            <strong>Durée du prêt : </strong> {folder.loanDuration} ans
          </p>
        </Card>
      );
    });

  if (loading) {
    return <Spin />;
  }

  return (
    <Container>
      <LogoTitleContainer>
        <FolderOutlined style={{ fontSize: "32px", color: MAIN_COLOR }} />
        <Title level={2} style={{ color: "white" }}>
          Mes dossiers
        </Title>
      </LogoTitleContainer>
      <StyledTabs activeKey={activeTab} onChange={handleTabChange} centered>
        <TabPane
          tab={
            <TabContent>
              <LargeBadge
                count={waitingForBankAppointment.length}
                style={{ backgroundColor: MAIN_COLOR, marginRight: "8px" }}
              />
              En attente d'un RDV banque
            </TabContent>
          }
          key="1"
        >
          <Description>{getDescription()}</Description>
          {renderFolderCards(waitingForBankAppointment)}
        </TabPane>
        <TabPane
          tab={
            <TabContent>
              <LargeBadge
                count={waitingForOfferSignature.length}
                style={{
                  backgroundColor: BANK_ICON_2_COLOR,
                  marginRight: "8px",
                }}
              />
              En attente signature offre
            </TabContent>
          }
          key="2"
        >
          <Description>{getDescription()}</Description>
          {renderFolderCards(waitingForOfferSignature)}
        </TabPane>
        <TabPane
          tab={
            <TabContent>
              <LargeBadge
                count={waitingForNotaryAppointment.length}
                style={{
                  backgroundColor: BANK_ICON_3_COLOR,
                  marginRight: "8px",
                }}
              />
              En attente RDV notaire
            </TabContent>
          }
          key="3"
        >
          <Description>{getDescription()}</Description>
          {renderFolderCards(waitingForNotaryAppointment)}
        </TabPane>
      </StyledTabs>
    </Container>
  );
};

export default MyFoldersBank;
