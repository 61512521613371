import { Layout } from "antd";
import React from "react";

const { Footer } = Layout;

const AppFooter: React.FC = () => (
  <Footer style={{ textAlign: "center", padding: "10px 20px" }}>
    © 2024 Reeady. Tous droits réservés.
  </Footer>
);

export default AppFooter;
