import { checkApiError } from "../../apiCheckError";
import { updateFolderById } from "../../services/RealEstateFolder.service";
import {
  Justificative,
  FileStatus,
  Situation,
  PurchaseIn,
  CurrentResidentialSituation,
} from "../../types/REFolder.interface";

const createJustificative = (
  name: string,
  title: string,
  personNumber: number,
): Justificative => {
  return {
    name: `person_${personNumber}_${name}_identity`,
    title: `${title}`,
    description: `${title}`,
    validityState: FileStatus.Empty,
  };
};

export const generateIdentityDocuments = async (
  maritalStatus: Situation,
  homeStatus: boolean,
  hasChildren: boolean,
  numPeople: number,
  ownershipType: PurchaseIn,
  folderId: string,
): Promise<Justificative[]> => {
  let updatedIdentities: Justificative[] = [];

  if (homeStatus) {
    checkApiError(
      (
        await updateFolderById(folderId, {
          currentResidentialSituation: CurrentResidentialSituation.Owner,
        })
      ).respStatus,
    );
  } else {
    checkApiError(
      (
        await updateFolderById(folderId, {
          currentResidentialSituation: CurrentResidentialSituation.Renter,
        })
      ).respStatus,
    );
  }

  checkApiError(
    (
      await updateFolderById(folderId, {
        situation: maritalStatus,
        purchaseIn: ownershipType,
        numPeople: numPeople,
        hasChildren: hasChildren,
      })
    ).respStatus,
  );

  for (let i = 1; i <= numPeople; i++) {
    updatedIdentities.push(
      createJustificative("piece_identite", "Pièce d'identité", i),
      createJustificative("releve_compte_1", "Relevé de compte M-1", i),
      createJustificative("releve_compte_2", "Relevé de compte M-2", i),
      createJustificative("releve_compte_3", "Relevé de compte M-3", i),
      createJustificative(
        "justificatif_domicile",
        "Justificatif de domicile de moins de 3 mois",
        i,
      ),
    );

    if (maritalStatus === Situation.Married) {
      updatedIdentities.push(
        createJustificative("contrat_mariage", "Contrat de mariage ou pacs", i),
      );
    } else if (maritalStatus === Situation.Divorced) {
      updatedIdentities.push(
        createJustificative("jugement_divorce", "Jugement de divorce", i),
      );
      updatedIdentities.push(
        createJustificative(
          "etat_liquidatif",
          "Etat liquidatif de la communauté",
          i,
        ),
      );
      updatedIdentities.push(
        createJustificative(
          "justificatif_pension",
          "Justificatif de pension alimentaire",
          i,
        ),
      );
    }

    if (homeStatus) {
      updatedIdentities.push(
        createJustificative("titre_propriete", "Titre de propriété", i),
      );
      updatedIdentities.push(
        createJustificative("taxe_fonciere", "Taxe foncière", i),
      );
    } else {
      updatedIdentities.push(createJustificative("bail", "Bail", i));
      updatedIdentities.push(
        createJustificative("quittance_1", "Quittance de loyer M-1", i),
      );
      updatedIdentities.push(
        createJustificative("quittance_2", "Quittance de loyer M-2", i),
      );
      updatedIdentities.push(
        createJustificative("quittance_3", "Quittance de loyer M-3", i),
      );
    }

    if (hasChildren) {
      updatedIdentities.push(
        createJustificative("livret_famille", "Livret de famille", i),
      );
    }

    if (ownershipType === PurchaseIn.InSCI) {
      updatedIdentities.push(
        createJustificative("statuts_sci", "Statuts de la SCI", i),
      );
      updatedIdentities.push(
        createJustificative("kbis_sci", "Kbis de la SCI", i),
      );
      updatedIdentities.push(
        createJustificative("previsionnel", "Previsionnel", i),
      );
    }
  }

  return updatedIdentities;
};
