export const CommonProfile = [
  "Pièce d'identité",
  "Relevé de compte M-1",
  "Relevé de compte M-2",
  "Relevé de cmpte M-3",
  "Justificatif de domicile",
];

export const ChildProfile = ["Livret de famille"];

export const MarriedProfile = ["Contrat de mariage ou pacs"];

export const DivorcedProfile = [
  "Jugement de divorce",
  "Etat liquidatif",
  "Pension alimentaire",
];

export const OwnerProfile = ["Taxe foncière", "Titre de propriété"];

export const RenterProfile = [
  "Bail de location",
  "Quittance de loyer M-1",
  "Quittance de loyer M-2",
  "Quittance de loyer M-3",
];

export const SCIProfile = [
  "Statuts de la SCI",
  "Kbis de la SCI",
  "Prévisionnel",
];

export const CommonFinancial = [
  "Avis d'imposition N-1",
  "Avis d'imposition N-2",
  "Dernier relevé d'épargne",
];

export const EmployedFinancial = [
  "Contrat de travail",
  "Bulletin de salaire M-1",
  "Bulletin de salaire M-2",
  "Bulletin de salaire M-3",
  "Bulletin décembr N-1",
];

export const CAFFinancial = ["Attestation CAF"];

export const OngoingLoansFinancial = ["Tableau d'amortissement"];

export const desiredContributionFinancial = ["Justificatif d'apport"];

export const CommonProject = [
  "Compromis de vente",
  "Diagnostic de performance énergétique",
];

export const renovationBudgetProject = ["Devis de travaux"];

export const RLProject = ["Baux de location", "Estimation locative"];

export const newProject = [
  "Contrat de construction",
  "Plan de construction",
  "Permis de construire",
  "Devis",
  "Assurances",
];
