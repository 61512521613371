import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Button, message } from "antd";
import {
  HomeOutlined,
  CheckCircleOutlined,
  EuroCircleOutlined,
  BuildOutlined,
  EnvironmentOutlined,
  PercentageOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  FileOutlined,
  FileTextOutlined,
  StarOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { FolderDetails, REFolderStatus } from "../../types/REFolder.interface";
import { UserType } from "../../types";
import {
  findFolderById,
  updateFolderById,
} from "../../services/RealEstateFolder.service";
import { getUserById } from "../../services/users.service";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MAIN_COLOR } from "../../utils";
import { checkApiError } from "../../apiCheckError";

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 18px;
`;

const StyledBoldText = styled(StyledText)`
  font-weight: bold;
  margin-right: 8px;
`;

interface ReviewProps {
  folderId: string;
}

const Review: React.FC<ReviewProps> = ({ folderId }) => {
  const navigate = useNavigate();
  const [folderDetails, setFolderDetails] = useState<FolderDetails | null>(
    null,
  );
  const [userDetails, setUserDetails] = useState<UserType | null>(null);

  useEffect(() => {
    const fetchFolderDetails = async () => {
      const response = await findFolderById(folderId);
      checkApiError(response.respStatus);
      const folder = response.respData;
      setFolderDetails(folder);

      const userResponse = await getUserById(folder.userId);
      checkApiError(userResponse.respStatus);
      const user = userResponse.respData;
      setUserDetails(user);
    };

    fetchFolderDetails();
  }, [folderId]);

  if (!folderDetails || !userDetails) {
    return null;
  }

  const { scoring, summaryNoteFile, financingPlanFile, status, bidInfos } =
    folderDetails;

  const handleBidStatusChange = async () => {
    const newStatus =
      status === REFolderStatus.Auction ||
      status === REFolderStatus.DocumentVerification
        ? REFolderStatus.Auctioned
        : REFolderStatus.Auction;
    checkApiError(
      (await updateFolderById(folderId, { status: newStatus })).respStatus,
    );
    setFolderDetails({ ...folderDetails, status: newStatus });
    message.success(`Statut changé en ${newStatus}`);
  };

  const handleBidView = () => {
    navigate(`/mes-dossiers/${folderId}/enchere`);
  };

  const formatNumber = (num: number) => {
    return num.toLocaleString("fr-FR");
  };

  const hasBids =
    bidInfos &&
    bidInfos.some(
      (bidInfo) => bidInfo.bidPropositions.length !== 0 && bidInfo.active,
    );

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={16}>
          <Card
            title="Informations sur le dossier"
            bordered={false}
            style={{ border: `1px solid ${MAIN_COLOR}` }}
          >
            <Row>
              <Col xs={24} md={12}>
                <p>
                  <HomeOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <StyledBoldText>Type de bien :</StyledBoldText>
                  <StyledText>{folderDetails.propertyType}</StyledText>
                </p>
                <p>
                  <CheckCircleOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Condition :</StyledBoldText>
                  <StyledText>{folderDetails.condition}</StyledText>
                </p>
                <p>
                  <EuroCircleOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Prix du bien :</StyledBoldText>
                  <StyledText>
                    {formatNumber(folderDetails.propertyPrice)} €
                  </StyledText>
                </p>
                <p>
                  <BuildOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Budget des travaux :</StyledBoldText>
                  <StyledText>
                    {formatNumber(folderDetails.renovationBudget)} €
                  </StyledText>
                </p>
                <p>
                  <EnvironmentOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Adresse :</StyledBoldText>
                  <StyledText>{folderDetails.address}</StyledText>
                </p>
              </Col>
              <Col xs={24} md={12}>
                <p>
                  <PercentageOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Frais de notaire :</StyledBoldText>
                  <StyledText>{folderDetails.notaryFeesPercentage}%</StyledText>
                </p>
                <p>
                  <ClockCircleOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Durée du prêt :</StyledBoldText>
                  <StyledText>{folderDetails.loanDuration} ans</StyledText>
                </p>
                <p>
                  <DollarOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Apport souhaité :</StyledBoldText>
                  <StyledText>
                    {formatNumber(folderDetails.desiredContribution)} €
                  </StyledText>
                </p>
                <p>
                  <InfoCircleOutlined
                    style={{ color: MAIN_COLOR, marginRight: 8 }}
                  />
                  <StyledBoldText>Statut :</StyledBoldText>
                  <StyledText>{folderDetails.status}</StyledText>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card
            title="Retour du courtier"
            bordered={false}
            style={{ border: `1px solid ${MAIN_COLOR}` }}
          >
            <p>
              <FileOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
              <StyledBoldText>Note de synthèse :</StyledBoldText>
              <StyledText>
                <a
                  href={summaryNoteFile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Note de synthèse
                </a>
              </StyledText>
            </p>
            <p>
              <FileTextOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
              <StyledBoldText>Plan de financement :</StyledBoldText>
              <StyledText>
                <a
                  href={financingPlanFile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Plan de financement
                </a>
              </StyledText>
            </p>
            <p>
              <StarOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
              <StyledBoldText>Note attribuée par le courtier :</StyledBoldText>
              <StyledText>{scoring}</StyledText>
            </p>
            {status === REFolderStatus.Auction ||
            status === REFolderStatus.Auctioned ||
            status === REFolderStatus.DocumentVerification ? (
              <Button
                type="primary"
                onClick={handleBidStatusChange}
                style={{ marginTop: 16, marginRight: 16 }}
                icon={
                  status === REFolderStatus.Auction ||
                  status === REFolderStatus.DocumentVerification ? (
                    <BuildOutlined />
                  ) : (
                    <CloseCircleOutlined />
                  )
                }
              >
                {status === REFolderStatus.Auction ||
                status === REFolderStatus.DocumentVerification
                  ? "Mettre aux enchères"
                  : "Retirer des enchères"}
              </Button>
            ) : null}
            {hasBids && (
              <Button
                type="primary"
                onClick={handleBidView}
                style={{ marginTop: 16 }}
                icon={<EyeOutlined />}
              >
                Consulter mes enchères
              </Button>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Review;
