// api paths
export const API_URL: string = process.env.REACT_APP_API_base_url as string;

//AUTH
export const API_LOGIN: string = process.env.REACT_APP_API_login as string;
export const API_LOGOUT: string = process.env.REACT_APP_API_logout as string;
export const API_SIGNUP: string = process.env.REACT_APP_API_signup as string;
export const API_MAGIC_LINK: string = process.env
  .REACT_APP_API_login_magic_link as string;
export const API_ASK_MAGIC_LINK: string = process.env
  .REACT_APP_API_ask_magic_link as string;
export const API_BANK_ACCOUNT: string = process.env
  .REACT_APP_API_new_bank_account as string;
//USERS

export const API_USERS: string = process.env.REACT_APP_API_users as string;

export const API_CONFIRM_EMAIL: string = process.env
  .REACT_APP_API_confirm_email as string;
export const API_UPDATE_EMAIL: string = process.env
  .REACT_APP_API_update_my_email as string;
export const API_UPDATE_INFOS: string = process.env
  .REACT_APP_API_update_my_infos as string;
export const API_ASK_RESET_PASSWORD: string = process.env
  .REACT_APP_API_ask_reset_password as string;
export const API_RESET_PASSWORD: string = process.env
  .REACT_APP_API_reset_password as string;
export const API_UPDATE_PASSWORD: string = process.env
  .REACT_APP_API_update_my_password as string;

//REAL ESTATE FOLDER
export const API_REA_FOLDERS: string = process.env
  .REACT_APP_API_rea_folders as string;
export const API_ADMIN_REA_FOLDERS: string = process.env
  .REACT_APP_API_admin_rea_folders as string;
export const API_INVALID_FILES: string = process.env
  .REACT_APP_API_invalid_files as string;
export const API_REA_FOLDER_OK: string = process.env
  .REACT_APP_API_refolder_ok as string;
export const API_REA_FOLDER_BANKS: string = process.env
  .REACT_APP_API_send_refolder_to_banks as string;
export const API_BANK_REA_FOLDERS: string = process.env
  .REACT_APP_API_bank_rea_folders as string;
export const API_BANK_sendProposition: string = process.env
  .REACT_APP_API_sendProposition as string;
export const API_validate_bid: string = process.env
  .REACT_APP_API_validate_bid as string;
