import React, { useEffect, useState } from "react";
import { Card } from "antd";
import {
  DollarOutlined,
  BankOutlined,
  HomeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  BACKGROUND_COLOR,
  BANK_ICON_2_COLOR,
  BANK_ICON_3_COLOR,
  HOOVER_COLOR,
  MAIN_COLOR,
  REAL_BACKGROUND_COLOR,
} from "../../utils";
import { getBankFolders } from "../../services/RealEstateFolder.service";
import { checkApiError } from "../../apiCheckError";
import { BidStatus, BidProposition } from "../../types/REFolder.interface";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
`;

const ContentRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  gap: 20px;
  padding-left: 50px;
`;

const StyledCard = styled(Card)`
  width: 70%;
  background-color: ${BACKGROUND_COLOR};
  border: 1px solid ${BACKGROUND_COLOR};
  .ant-card-head-title {
    color: white;
  }
  .ant-card-body {
    color: white;
  }
`;

const StatsCardContainer = styled.div`
  width: 40%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const StatsCard = styled(Card)`
  background-color: ${BACKGROUND_COLOR};
  border: 1px solid ${BACKGROUND_COLOR};
  text-align: center;
  .ant-card-body {
    color: ${MAIN_COLOR};
    font-size: 24px;
    font-weight: bold;
  }
`;

const GraphRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  padding-left: 50px;
`;

const PieChartCard = styled(Card)`
  width: 50%;
  background-color: ${BACKGROUND_COLOR};
  border: 1px solid ${BACKGROUND_COLOR};
  .ant-card-head-title {
    color: white;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  .anticon {
    color: ${MAIN_COLOR};
    margin-right: 10px;
  }
`;

const CenteredContentCard = styled(StyledCard)`
  .ant-card-body {
    text-align: left;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
  }
`;

const StatsBank: React.FC = () => {
  const [wonBidsData, setWonBidsData] = useState<number[]>(
    new Array(12).fill(0),
  );
  const [salesData, setSalesData] = useState({
    domiciliationOfIncomes: 0,
    domiciliationOfSavings: 0,
    homeInsurance: 0,
    otherConditions: 0,
    totalFolders: 0,
  });
  const [averageProcessingTime, setAverageProcessingTime] = useState<number>(0);

  useEffect(() => {
    const fetchWonBids = async () => {
      try {
        const response = await getBankFolders();
        checkApiError(response.respStatus);

        const bidsPerMonth = new Array(12).fill(0);
        let totalDays = 0;
        let count = 0;
        let salesSummary = {
          domiciliationOfIncomes: 0,
          domiciliationOfSavings: 0,
          homeInsurance: 0,
          otherConditions: 0,
          totalFolders: 0,
        };

        response.respData.forEach((folder) => {
          folder.bidInfos?.forEach((bidInfo) => {
            const waitingForOffer = bidInfo.historyStatuses.find(
              (status) => status.status === BidStatus.WaitingForOffer,
            );
            const bidWon = bidInfo.historyStatuses.find(
              (status) => status.status === BidStatus.BidWon,
            );

            if (waitingForOffer && bidWon) {
              const offerDate = new Date(waitingForOffer.created_at);
              const wonDate = new Date(bidWon.created_at);
              const daysBetween = Math.floor(
                (wonDate.getTime() - offerDate.getTime()) /
                  (1000 * 60 * 60 * 24),
              );

              totalDays += daysBetween;
              count++;

              const month = wonDate.getMonth();
              bidsPerMonth[month] += 1;

              salesSummary.totalFolders += 1;
              bidInfo.bidPropositions.forEach((proposition: BidProposition) => {
                if (proposition.domiciliationOfIncomes)
                  salesSummary.domiciliationOfIncomes += 1;
                if (proposition.domiciliationOfSavings)
                  salesSummary.domiciliationOfSavings += 1;
                if (proposition.homeInsurance) salesSummary.homeInsurance += 1;
                if (proposition.otherConditions) {
                  salesSummary.otherConditions +=
                    proposition.otherConditions.split(",").length;
                }
              });
            }
          });
        });

        const averageTime = count > 0 ? Math.floor(totalDays / count) : 0;
        setWonBidsData(bidsPerMonth);
        setSalesData(salesSummary);
        setAverageProcessingTime(averageTime);
      } catch (error) {
        console.error("Erreur lors de la récupération des dossiers :", error);
      }
    };

    fetchWonBids();
  }, []);

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      background: BACKGROUND_COLOR,
      height: 350,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: BANK_ICON_2_COLOR,
            },
          ],
        },
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "14px",
      },
      y: {
        formatter: (val) =>
          `${val} enchère${val > 1 ? "s" : ""} remportée${val > 1 ? "s" : ""}`,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "jan",
        "fev",
        "mar",
        "avr",
        "mai",
        "juin",
        "jui",
        "aou",
        "sep",
        "oct",
        "nov",
        "dec",
      ],
      position: "bottom",
      labels: {
        offsetY: 0,
        style: {
          colors: BANK_ICON_2_COLOR,
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: BANK_ICON_2_COLOR,
          fontWeight: "bold",
        },
      },
    },
    grid: {
      borderColor: HOOVER_COLOR,
      strokeDashArray: 5,
    },
    title: {
      text: "",
    },
  };

  const donutChartOptions: ApexOptions = {
    chart: {
      type: "donut",
    },
    labels: [
      "Ma banque",
      "Autre banque",
      "Autre banque",
      "Autre banque",
      "Autre banque",
    ],
    colors: [
      MAIN_COLOR,
      HOOVER_COLOR,
      BANK_ICON_3_COLOR,
      BANK_ICON_2_COLOR,
      REAL_BACKGROUND_COLOR,
    ],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      style: {
        fontSize: "14px",
      },
      y: {
        formatter: (val) => `${val}%`,
      },
    },
    legend: {
      show: true,
      position: "right",
      labels: {
        colors: ["white", "white", "white", "white", "white", "white"],
        useSeriesColors: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "65%",
          labels: {
            show: false,
          },
        },
      },
    },
  };

  const donutChartSeries = [30, 24, 20, 14, 12];

  const chartSeries = [
    {
      name: "Remportées",
      data: wonBidsData,
    },
  ];

  return (
    <Container>
      <ContentRow>
        <StyledCard title="Mes enchères remportées">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={350}
          />
        </StyledCard>
        <StatsCardContainer>
          <StatsCard title="Clients conservés">
            <div>333 / 481</div>
          </StatsCard>
          <StatsCard title="Nouveaux clients">
            <div>148</div>
          </StatsCard>
          <StatsCard title="Temps global d'un dossier">
            <div>{averageProcessingTime}</div>
          </StatsCard>
          <StatsCard title="Agences touchées">
            <div>8</div>
          </StatsCard>
        </StatsCardContainer>
      </ContentRow>

      <GraphRow>
        <CenteredContentCard
          title="Contreparties vendues"
          style={{ height: 360 }}
        >
          <IconText>
            <DollarOutlined />
            Domiciliation revenu : {salesData.domiciliationOfIncomes} /{" "}
            {salesData.totalFolders} dossiers
          </IconText>
          <IconText>
            <BankOutlined />
            Domiciliation épargne : {salesData.domiciliationOfSavings} /{" "}
            {salesData.totalFolders} dossiers
          </IconText>
          <IconText>
            <HomeOutlined />
            Assurance groupe : {salesData.homeInsurance} /{" "}
            {salesData.totalFolders} dossiers
          </IconText>
          <IconText>
            <FileTextOutlined />
            Autres conditions : {salesData.otherConditions} condition(s)
            supplémentaire(s) vendue(s)
          </IconText>
        </CenteredContentCard>

        <PieChartCard title="Ma position marché">
          <Chart
            options={donutChartOptions}
            series={donutChartSeries}
            type="donut"
            height={350}
          />
        </PieChartCard>
      </GraphRow>
    </Container>
  );
};

export default StatsBank;
