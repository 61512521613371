import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Collapse, Radio, Switch, Checkbox, Input } from "antd";
import styled from "styled-components";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { generateIdentityDocuments } from "./IdentityAccordion";
import { generateIncomeDocuments } from "./IncomesAccordion";
import { generateProjectDocuments } from "./GlobalQuestions";
import CustomDragger from "./CustomDragger";
import {
  FolderDetails,
  Justificative,
  PurchaseIn,
  Situation,
  FileStatus,
  CurrentResidentialSituation,
  InvestmentType,
  Condition,
} from "../../types/REFolder.interface";

const { Panel } = Collapse;
const { Group: RadioGroup } = Radio;

const Container = styled.div`
  padding: 20px;
`;

const CardContainer = styled.div`
  margin-bottom: 20px;
`;

const DraggersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-bottom: 24px;

  .dragger-item {
    flex: 1 1 calc(33.33% - 24px);
    max-width: calc(33.33% - 24px);
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const OptionGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-size: 16px;
  margin-right: 8px;
`;

interface AccordionFilesProps {
  renovationBudget: number;
  desiredContribution: number;
  folder: FolderDetails;
}

const AccordionFiles: React.FC<AccordionFilesProps> = ({
  renovationBudget,
  desiredContribution,
  folder,
}) => {
  const { id: folderId } = useParams<{ id: string }>();
  const [numPeople, setNumPeople] = useState<number>(folder.numPeople || 1);
  const [identityDocuments, setIdentityDocuments] = useState<Justificative[]>(
    [],
  );
  const [incomesDocuments, setIncomesDocuments] = useState<Justificative[]>([]);
  const [projectDocuments, setProjectDocuments] = useState<Justificative[]>([]);

  const [maritalStatus, setMaritalStatus] = useState<Situation>(
    folder.situation || Situation.Single,
  );
  const [homeStatus, setHomeStatus] = useState<boolean>(
    folder.currentResidentialSituation === CurrentResidentialSituation.Owner ||
      false,
  );
  const [hasChildren, setHasChildren] = useState<boolean>(
    folder.hasChildren || false,
  );

  const [employee, setEmployee] = useState<boolean>(folder.isEmployed || false);
  const [cafBeneficiary, setCafBeneficiary] = useState<boolean>(
    folder.hasCAF || false,
  );
  const [loansInProgress, setLoansInProgress] = useState<boolean>(
    folder.hasOngoingLoans || false,
  );

  const [residenceType, setResidenceType] = useState<boolean>(
    folder.investmentType === InvestmentType.MainResidence || false,
  );
  const [propertyCondition, setPropertyCondition] = useState<boolean>(
    folder.condition === Condition.New || false,
  );
  const [ownershipType, setOwnershipType] = useState<PurchaseIn>(
    folder.purchaseIn || PurchaseIn.Solo,
  );

  const [updatedIdentityDocuments, setUpdatedIdentityDocuments] = useState<
    Justificative[]
  >([]);
  const [updatedIncomesDocuments, setUpdatedIncomesDocuments] = useState<
    Justificative[]
  >([]);
  const [updatedProjectDocuments, setUpdatedProjectDocuments] = useState<
    Justificative[]
  >([]);

  useEffect(() => {
    if (!folderId) return;

    const updateDocumentsFromFiles = () => {
      const identityDocs: Justificative[] = [];
      const incomeDocs: Justificative[] = [];
      const projectDocs: Justificative[] = [];

      folder.files.forEach((file) => {
        if (file.name.includes("identity")) {
          identityDocs.push({
            name: file.name,
            title: file.name,
            description: file.name,
            validityState: file.status,
          });
        } else if (file.name.includes("income")) {
          incomeDocs.push({
            name: file.name,
            title: file.name,
            description: file.name,
            validityState: file.status,
          });
        } else if (file.name.includes("project")) {
          projectDocs.push({
            name: file.name,
            title: file.name,
            description: file.name,
            validityState: file.status,
          });
        }
      });

      setUpdatedIdentityDocuments(identityDocs);
      setUpdatedIncomesDocuments(incomeDocs);
      setUpdatedProjectDocuments(projectDocs);
    };

    updateDocumentsFromFiles();

    const generate = async () => {
      const generatedIdentityDocuments = await generateIdentityDocuments(
        maritalStatus,
        homeStatus,
        hasChildren,
        numPeople,
        ownershipType,
        folderId,
      );
      setIdentityDocuments(generatedIdentityDocuments);
    };

    generate();
  }, [
    maritalStatus,
    homeStatus,
    hasChildren,
    numPeople,
    ownershipType,
    folderId,
    folder.files,
  ]);

  useEffect(() => {
    if (!folderId) return;
    const generate = async () => {
      const generatedIncomesDocuments = await generateIncomeDocuments(
        numPeople,
        employee,
        cafBeneficiary,
        loansInProgress,
        desiredContribution,
        folderId,
      );
      setIncomesDocuments(generatedIncomesDocuments);
    };

    generate();
  }, [
    numPeople,
    employee,
    cafBeneficiary,
    loansInProgress,
    desiredContribution,
    folderId,
  ]);

  useEffect(() => {
    const generate = async () => {
      if (!folderId) return;
      const generatedProjectDocuments = await generateProjectDocuments(
        residenceType,
        propertyCondition,
        renovationBudget,
        folderId,
      );
      setProjectDocuments(generatedProjectDocuments);
    };

    generate();
  }, [
    residenceType,
    propertyCondition,
    ownershipType,
    renovationBudget,
    folderId,
  ]);

  const handleOwnershipChange = (e: any) => {
    setOwnershipType(e.target.value);
    if (e.target.value === PurchaseIn.Solo) {
      setNumPeople(1);
    }
  };

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const calculateDocumentStatusCounts = (
    documents: Justificative[],
    length: number,
  ) => {
    let validated = 0;
    let inReview = 0;
    let invalidated = 0;

    documents.forEach((doc) => {
      if (doc.validityState === FileStatus.Validated) {
        validated++;
      } else if (doc.validityState === FileStatus.InReview) {
        inReview++;
      } else if (doc.validityState === FileStatus.Invalidated) {
        invalidated++;
      }
    });

    return {
      validated,
      inReview,
      invalidated,
      total: length,
    };
  };

  const handleFileUpload = (updatedFile: Justificative, category: string) => {
    const updateDocuments = (documents: Justificative[]) => {
      return documents.map((doc) =>
        doc.name === updatedFile.name ? updatedFile : doc,
      );
    };

    if (category === "identity") {
      setIdentityDocuments(updateDocuments(identityDocuments));
    } else if (category === "incomes") {
      setIncomesDocuments(updateDocuments(incomesDocuments));
    } else if (category === "project") {
      setProjectDocuments(updateDocuments(projectDocuments));
    }
  };

  return (
    <Container>
      <CardContainer>
        <Card title="Questions Globales" bordered={false}>
          <p style={{ marginBottom: 16, fontWeight: "bold" }}>
            Ces questions vont permettre de déterminer quelles seront les pièces
            nécessaires à la création du dossier :
          </p>
          <OptionsContainer>
            <OptionGroup onClick={stopPropagation}>
              <Label>Résidence Locative</Label>
              <Switch checked={residenceType} onChange={setResidenceType} />
              <Label style={{ marginLeft: 8 }}>Résidence Principale</Label>
            </OptionGroup>
            {residenceType && (
              <OptionGroup onClick={stopPropagation}>
                <Label>Ancien</Label>
                <Switch
                  checked={propertyCondition}
                  onChange={setPropertyCondition}
                />
                <Label style={{ marginLeft: 8 }}>Neuf</Label>
              </OptionGroup>
            )}
            <OptionGroup onClick={stopPropagation}>
              <RadioGroup
                value={ownershipType}
                onChange={handleOwnershipChange}
              >
                <Radio value={PurchaseIn.Solo}>Seul</Radio>
                <Radio value={PurchaseIn.WithSeveral}>Commun</Radio>
                <Radio value={PurchaseIn.InSCI}>SCI</Radio>
              </RadioGroup>
              {(ownershipType === PurchaseIn.WithSeveral ||
                ownershipType === PurchaseIn.InSCI) && (
                <div style={{ marginLeft: 16 }}>
                  <Label>Combien de personnes ?</Label>
                  <Input
                    type="number"
                    value={numPeople}
                    onChange={(e) => setNumPeople(Number(e.target.value))}
                    placeholder="Nombre de personnes"
                    style={{ width: 200 }}
                    min={1}
                  />
                </div>
              )}
            </OptionGroup>
          </OptionsContainer>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card title="Informations" bordered={false}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              header={
                <HeaderContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {(() => {
                      const counts = calculateDocumentStatusCounts(
                        updatedIdentityDocuments,
                        identityDocuments.length,
                      );
                      return (
                        <>
                          <span>
                            <CheckCircleOutlined style={{ color: "green" }} />{" "}
                            {counts.validated}/{counts.total}
                          </span>
                          {counts.inReview > 0 && (
                            <span>
                              <LoadingOutlined style={{ color: "orange" }} />{" "}
                              {counts.inReview}
                            </span>
                          )}
                          {counts.invalidated > 0 && (
                            <span>
                              <CloseCircleOutlined style={{ color: "red" }} />{" "}
                              {counts.invalidated}
                            </span>
                          )}
                        </>
                      );
                    })()}
                    <span>Identité et comptes</span>
                  </div>
                  <OptionsContainer>
                    <OptionGroup onClick={stopPropagation}>
                      <RadioGroup
                        value={maritalStatus}
                        onChange={(e) => setMaritalStatus(e.target.value)}
                      >
                        <Radio value={Situation.Married}>Marié/Pacsé</Radio>
                        <Radio value={Situation.Divorced}>Divorcé</Radio>
                        <Radio value={Situation.Single}>Célibataire</Radio>
                      </RadioGroup>
                    </OptionGroup>
                    <OptionGroup onClick={stopPropagation}>
                      <Label>Locataire</Label>
                      <Switch checked={homeStatus} onChange={setHomeStatus} />
                      <Label style={{ marginLeft: 8 }}>Propriétaire</Label>
                    </OptionGroup>
                    <OptionGroup onClick={stopPropagation}>
                      <Checkbox
                        checked={hasChildren}
                        onChange={(e) => setHasChildren(e.target.checked)}
                      >
                        Enfants
                      </Checkbox>
                    </OptionGroup>
                  </OptionsContainer>
                </HeaderContainer>
              }
              key="1"
            >
              {Array.from({ length: numPeople }).map((_, index) => (
                <div key={index}>
                  <h3>Personne {index + 1}</h3>
                  <DraggersContainer>
                    {identityDocuments
                      .filter((item) =>
                        item.name.includes(`person_${index + 1}`),
                      )
                      .map((item) => (
                        <div className="dragger-item" key={item.name}>
                          <CustomDragger
                            fileName={item.name}
                            pieceTitle={item.title}
                            pieceDescription={item.description}
                            validityState={item.validityState}
                            folderId={folderId!}
                            folder={folder!}
                            subCategory="identity_subCategory"
                            onFileUpload={(updatedFile) =>
                              handleFileUpload(updatedFile, "identity")
                            }
                          />
                        </div>
                      ))}
                  </DraggersContainer>
                </div>
              ))}
            </Panel>
            <Panel
              header={
                <HeaderContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {(() => {
                      const counts = calculateDocumentStatusCounts(
                        updatedIncomesDocuments,
                        incomesDocuments.length,
                      );
                      return (
                        <>
                          <span>
                            <CheckCircleOutlined style={{ color: "green" }} />{" "}
                            {counts.validated}/{counts.total}
                          </span>
                          {counts.inReview > 0 && (
                            <span>
                              <LoadingOutlined style={{ color: "orange" }} />{" "}
                              {counts.inReview}
                            </span>
                          )}
                          {counts.invalidated > 0 && (
                            <span>
                              <CloseCircleOutlined style={{ color: "red" }} />{" "}
                              {counts.invalidated}
                            </span>
                          )}
                        </>
                      );
                    })()}
                    <span>Revenus et charges</span>
                  </div>
                  <OptionsContainer>
                    <OptionGroup onClick={stopPropagation}>
                      <Checkbox
                        checked={employee}
                        onChange={(e) => setEmployee(e.target.checked)}
                      >
                        Employé
                      </Checkbox>
                    </OptionGroup>
                    <OptionGroup onClick={stopPropagation}>
                      <Checkbox
                        checked={cafBeneficiary}
                        onChange={(e) => setCafBeneficiary(e.target.checked)}
                      >
                        Bénéficiaire de la CAF
                      </Checkbox>
                    </OptionGroup>
                    <OptionGroup onClick={stopPropagation}>
                      <Checkbox
                        checked={loansInProgress}
                        onChange={(e) => setLoansInProgress(e.target.checked)}
                      >
                        Prêt en cours
                      </Checkbox>
                    </OptionGroup>
                  </OptionsContainer>
                </HeaderContainer>
              }
              key="2"
            >
              {Array.from({ length: numPeople }).map((_, index) => (
                <div key={index}>
                  <h3>Personne {index + 1}</h3>
                  <DraggersContainer>
                    {incomesDocuments
                      .filter((item) =>
                        item.name.includes(`person_${index + 1}`),
                      )
                      .map((item) => (
                        <div className="dragger-item" key={item.name}>
                          <CustomDragger
                            fileName={item.name}
                            pieceTitle={item.title}
                            pieceDescription={item.description}
                            validityState={item.validityState}
                            folderId={folderId!}
                            folder={folder!}
                            subCategory="incomes_subCategory"
                            onFileUpload={(updatedFile) =>
                              handleFileUpload(updatedFile, "incomes")
                            }
                          />
                        </div>
                      ))}
                  </DraggersContainer>
                </div>
              ))}
            </Panel>
            <Panel
              header={
                <HeaderContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {(() => {
                      const counts = calculateDocumentStatusCounts(
                        updatedProjectDocuments,
                        projectDocuments.length,
                      );
                      return (
                        <>
                          <span>
                            <CheckCircleOutlined style={{ color: "green" }} />{" "}
                            {counts.validated}/{counts.total}
                          </span>
                          {counts.inReview > 0 && (
                            <span>
                              <LoadingOutlined style={{ color: "orange" }} />{" "}
                              {counts.inReview}
                            </span>
                          )}
                          {counts.invalidated > 0 && (
                            <span>
                              <CloseCircleOutlined style={{ color: "red" }} />{" "}
                              {counts.invalidated}
                            </span>
                          )}
                        </>
                      );
                    })()}
                    <span>Projet</span>
                  </div>
                </HeaderContainer>
              }
              key="3"
            >
              <DraggersContainer>
                {projectDocuments.map((item) => (
                  <div className="dragger-item" key={item.name}>
                    <CustomDragger
                      fileName={item.name}
                      pieceTitle={item.title}
                      pieceDescription={item.description}
                      validityState={item.validityState}
                      folderId={folderId!}
                      folder={folder!}
                      subCategory="project_subCategory"
                      onFileUpload={(updatedFile) =>
                        handleFileUpload(updatedFile, "project")
                      }
                    />
                  </div>
                ))}
              </DraggersContainer>
            </Panel>
          </Collapse>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default AccordionFiles;
