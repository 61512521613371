import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import styled from "styled-components";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  uploadFile,
  deleteFileById,
} from "../../services/RealEstateFolder.service";
import { BORDER_COLOR, MAIN_COLOR } from "../../utils";
import {
  FileData,
  FileStatus,
  FolderDetails,
  Justificative,
} from "../../types/REFolder.interface";
import { checkApiError } from "../../apiCheckError";

const { Dragger } = Upload;

const StyledDragger = styled(Dragger)<{ validityState?: FileStatus }>`
  .ant-upload.ant-upload-drag {
    border: ${({ validityState }) =>
      validityState === FileStatus.Validated
        ? "2px solid green"
        : validityState === FileStatus.Invalidated
          ? "2px solid red"
          : validityState === FileStatus.InReview
            ? `2px solid ${MAIN_COLOR}`
            : `2px solid ${BORDER_COLOR}`};
    border-style: solid;
  }
`;

interface CustomDraggerProps {
  fileName: string;
  pieceTitle: string;
  pieceDescription: string;
  validityState: FileStatus;
  folderId: string;
  folder: FolderDetails;
  subCategory: string;
  style?: React.CSSProperties;
  onFileUpload: (updatedFile: Justificative) => void;
}

const CustomDragger: React.FC<CustomDraggerProps> = ({
  fileName,
  pieceTitle,
  pieceDescription,
  validityState,
  folderId,
  folder,
  subCategory,
  style,
  onFileUpload,
  ...restProps
}) => {
  const [fileList, setFileList] = useState<FileData[]>([]);
  const [currentValidityState, setCurrentValidityState] =
    useState<FileStatus>(validityState);
  const [currentComment, setCurrentComment] = useState<string | undefined>();

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

  useEffect(() => {
    const fetchFiles = async () => {
      const files = folder.files.filter(
        (file: FileData) => file.name === fileName,
      );
      setFileList(files);
      if (files.length > 0) {
        setCurrentValidityState(files[0].status);
        setCurrentComment(files[0].comment);
      }
    };
    fetchFiles();
  }, [folderId, fileName, folder.files]);

  const handleBeforeUpload = async (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      message.error(
        "Le fichier est trop volumineux. La taille maximale est de 5 Mo.",
      );
      return Upload.LIST_IGNORE;
    }

    if (fileList.length > 0) {
      checkApiError(
        (await deleteFileById(folderId, fileList[0].id)).respStatus,
      );
    }
    const response = await uploadFile(folderId, file, fileName);
    checkApiError(response.respStatus);
    const uploadedFile = response.respData.files.find(
      (f: FileData) => f.name === fileName,
    );
    if (uploadedFile) {
      const updatedFile: Justificative = {
        name: uploadedFile.name,
        title: uploadedFile.name,
        description: "",
        validityState: FileStatus.InReview,
        url: uploadedFile.url,
      };
      setFileList([uploadedFile]);
      setCurrentValidityState(FileStatus.InReview);
      onFileUpload(updatedFile);
    }
    return false;
  };

  const handleRemove = async (file: FileData) => {
    if (file.uid) {
      checkApiError((await deleteFileById(folderId, file.uid)).respStatus);
    } else {
      checkApiError((await deleteFileById(folderId, file.id)).respStatus);
    }
    setFileList([]);
    setCurrentValidityState(FileStatus.Empty);
  };

  const description =
    currentValidityState === FileStatus.InReview ? (
      <>
        <LoadingOutlined style={{ marginRight: 4 }} />
        <span>en cours d'analyse par le courtier</span>
      </>
    ) : currentValidityState === FileStatus.Invalidated ? (
      <strong>Motif d'invalidation: {currentComment}</strong>
    ) : (
      pieceDescription
    );

  return (
    <StyledDragger
      {...restProps}
      name={fileName}
      style={style}
      validityState={currentValidityState}
      beforeUpload={handleBeforeUpload}
      fileList={fileList.map((file) => ({
        uid: file.id,
        name: file.name,
        status: "done" as const,
        url: file.url,
      }))}
      onRemove={(file) => handleRemove(file as unknown as FileData)}
      accept="image/*,video/*,application/pdf"
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{pieceTitle}</p>
      <p
        className="ant-upload-hint"
        style={{
          fontWeight:
            currentValidityState === FileStatus.InReview ? "bold" : "normal",
          color:
            currentValidityState === FileStatus.InReview ? "white" : "inherit",
        }}
      >
        {description}
      </p>
    </StyledDragger>
  );
};

export default CustomDragger;
