import { Layout, Menu, Dropdown, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/reeady.svg";
import { logout } from "../../../services/users.service";
import { checkApiError } from "../../../apiCheckError";

const { useToken } = theme;
const { Header } = Layout;

const NavbarBackoffice: React.FC = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const { token } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/backoffice") {
      setSelectedKey("1");
    } else if (location.pathname === "/backoffice/dossiers") {
      setSelectedKey("2");
    } else if (
      location.pathname.match(/^\/backoffice\/dossiers\/[a-zA-Z0-9-]+$/)
    ) {
      setSelectedKey("2");
    } else if (location.pathname === "/backoffice/profil") {
      setSelectedKey("3");
    }
  }, [location.pathname]);

  const handleLogoClick = () => {
    setSelectedKey("1");
  };

  const handleLogout = async () => {
    checkApiError((await logout()).respStatus);
    navigate("/backoffice/authentification");
  };

  const handleViewProfile = () => {
    navigate("/backoffice/profil");
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="view_profile" onClick={handleViewProfile}>
        Voir le profil
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        Déconnexion
      </Menu.Item>
    </Menu>
  );

  const items = [
    { key: "1", label: "Menu principal", link: "/backoffice" },
    { key: "2", label: "Dossiers", link: "/backoffice/dossiers" },
    { key: "3", label: "Profil", link: "/backoffice/profil" },
  ].filter((item): item is { key: string; label: string; link: string } =>
    Boolean(item),
  );

  const menuItems = items.map((item) => {
    if (item.label === "Profil") {
      return {
        key: item.key,
        label: (
          <Dropdown overlay={profileMenu} trigger={["hover"]}>
            <span
              style={{ display: "inline-block", width: "100%", height: "100%" }}
            >
              Profil
            </span>
          </Dropdown>
        ),
      };
    }
    return {
      key: item.key,
      label: <Link to={item.link}>{item.label}</Link>,
    };
  });

  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: token.colorBgBase,
        borderBottom: `1px solid ${token.colorPrimary}`,
      }}
    >
      <div>
        <Link to="/backoffice" onClick={handleLogoClick}>
          <img
            src={logo}
            alt="Logo"
            style={{ height: "40px", display: "block" }}
          />
        </Link>
      </div>
      <Menu
        mode="horizontal"
        selectedKeys={[selectedKey]}
        style={{
          flex: 1,
          justifyContent: "flex-end",
          backgroundColor: token.colorBgBase,
          borderBottom: "0px transparent",
        }}
        items={menuItems}
      />
    </Header>
  );
};

export default NavbarBackoffice;
