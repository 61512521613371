import { EuroOutlined } from "@ant-design/icons";
import { Form, Slider, Input, InputNumber, Typography, Row, Col } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { MAIN_COLOR } from "../../utils";

const { Title } = Typography;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .anticon {
    margin-right: 8px;
    font-size: 24px;
  }

  h4 {
    margin: 0;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

const SliderWrapper = styled.div`
  margin-bottom: 12px;
`;

const IconStyled = styled.div`
  .anticon {
    color: ${MAIN_COLOR};
  }
`;

const RightCol: React.FC<{
  control: any;
  defaultValues?: {
    loanDuration?: number;
    desiredContribution?: number;
    interestRate?: number;
    insuranceRate?: number;
  };
  loanAmount?: number;
  monthlyPayment?: number;
  monthlyInsurance?: number;
  totalCreditCost?: number;
  totalInsuranceCost?: number;
}> = ({ control, defaultValues }) => {
  return (
    <>
      <TitleWithIcon>
        <IconStyled>
          <EuroOutlined />
        </IconStyled>
        <Title level={4}>Informations financières</Title>
      </TitleWithIcon>
      <SliderWrapper>
        <FormItem label="Durée du prêt (années)">
          <Controller
            name="loanDuration"
            control={control}
            defaultValue={defaultValues?.loanDuration || 20}
            render={({ field }) => (
              <Slider
                {...field}
                min={5}
                max={25}
                marks={{ 5: "5", 10: "10", 15: "15", 25: "25" }}
              />
            )}
          />
        </FormItem>
      </SliderWrapper>
      <FormItem
        label="Apport souhaité"
        rules={[
          {
            required: true,
            message: "L'apport souhaité est obligatoire",
          },
        ]}
      >
        <Controller
          name="desiredContribution"
          control={control}
          defaultValue={defaultValues?.desiredContribution || "20 000"}
          render={({ field }) => (
            <Input
              type="text"
              {...field}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/\s/g, "");
                if (!isNaN(rawValue as any)) {
                  field.onChange(rawValue);
                }
              }}
              value={field.value ? field.value.toLocaleString("fr-FR") : ""}
              onBlur={(e) => {
                const rawValue = e.target.value.replace(/\s/g, "");
                const value = parseFloat(rawValue);
                field.onChange(value);
                e.target.value = value.toLocaleString("fr-FR");
              }}
            />
          )}
        />
      </FormItem>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={12}>
          <FormItem
            label="Taux d'intérêt annuel (%)"
            rules={[
              {
                required: true,
                message: "Le taux d'intérêt annuel est obligatoire",
              },
            ]}
          >
            <Controller
              name="interestRate"
              control={control}
              defaultValue={defaultValues?.interestRate || 4}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  min={0}
                  max={100}
                  step={0.01}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") || ""}
                  style={{ width: "100%" }}
                />
              )}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            label="Taux d'assurance (%)"
            rules={[
              {
                required: true,
                message: "Le taux d'assurance est obligatoire",
              },
            ]}
          >
            <Controller
              name="insuranceRate"
              control={control}
              defaultValue={defaultValues?.insuranceRate || 0.3}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  min={0}
                  max={100}
                  step={0.01}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") || ""}
                  style={{ width: "100%" }}
                />
              )}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default RightCol;
