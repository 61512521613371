import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { askMagicLink } from "../../../services/users.service";
import { BACKGROUND_COLOR } from "../../../utils";
import { checkApiError } from "../../../apiCheckError";

type LoginFormType = {
  email: string;
};

export default function LoginBankCard() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginFormType>();

  const onSubmit = async (data: LoginFormType) => {
    setIsSubmitting(true);
    setLoginError(null);
    checkApiError((await askMagicLink(data.email)).respStatus);
    message.success("Un email avec un lien de connexion a été envoyé");
    setIsSubmitting(false);
  };

  return (
    <div
      style={{
        backgroundColor: BACKGROUND_COLOR,
        padding: "24px",
        borderRadius: "8px",
        maxWidth: "400px",
        margin: "auto",
      }}
    >
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        {loginError && (
          <div style={{ color: "red", marginBottom: "24px" }}>{loginError}</div>
        )}
        <Form.Item
          label="Adresse mail"
          validateStatus={errors.email ? "error" : ""}
          help={errors.email ? errors.email.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                prefix={<UserOutlined />}
                placeholder="Rentrez votre adresse email"
              />
            )}
            rules={{
              required: "L'adresse email est obligatoire",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "L'adresse email n'est pas valide",
              },
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isSubmitting}
            style={{ marginTop: "24px" }}
          >
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
