import React, { useState } from "react";
import { Typography, Form, Input, Button, message } from "antd";
import { Controller, useForm } from "react-hook-form";
import { askResetPassword } from "../services/users.service";
import { theme } from "antd";
import { checkApiError } from "../apiCheckError";

const { Title } = Typography;

type ResetPasswordFormType = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const { token } = theme.useToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ResetPasswordFormType>();

  const onSubmit = async (data: ResetPasswordFormType) => {
    setIsSubmitting(true);
    checkApiError((await askResetPassword(data)).respStatus);
    message.success("Un email de réinitialisation a été envoyé.");
    setIsSubmitting(false);
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <div
        style={{
          backgroundColor: token.colorBgContainer,
          padding: "24px",
          borderRadius: "8px",
          maxWidth: "400px",
          margin: "auto",
          marginTop: "50px",
        }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          Réinitialiser le mot de passe
        </Title>
        <Form layout="vertical">
          <Form.Item
            label="Adresse email"
            validateStatus={errors.email ? "error" : ""}
            help={errors.email ? errors.email.message : ""}
            style={{ marginBottom: "24px" }}
          >
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  size="large"
                  placeholder="Rentrez votre adresse email"
                />
              )}
              rules={{
                required: "L'adresse email est obligatoire",
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: "L'adresse email n'est pas valide",
                },
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={handleSubmit(onSubmit)}
              block
              loading={isSubmitting}
              style={{ marginTop: "24px" }}
            >
              Réinitialiser le mot de passe
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
