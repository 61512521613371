import { theme } from "antd";
import logo from "../../assets/reeady.svg";
import LoginBackofficeCard from "../component/login/LoginBackofficeCard";

const AuthenticationBackoffice: React.FC = () => {
  const { token } = theme.useToken();

  return (
    <div>
      <div style={{ textAlign: "center", padding: "24px 0" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ height: "80px", marginBottom: "16px" }}
        />
        <h3
          style={{
            marginBottom: "24px",
            color: token.colorTextSecondary,
          }}
        >
          Accès au backoffice de Reeady
        </h3>
      </div>
      <div style={{ minHeight: "100vh" }}>
        <LoginBackofficeCard />
      </div>
    </div>
  );
};

export default AuthenticationBackoffice;
