import React, { useState, ReactNode } from "react";
import { loginMagicLink } from "../services/users.service";
import { UserType } from "../types";
import { checkApiError } from "../apiCheckError";

type AuthContextType = {
  isLoggedIn: boolean;
  justLoggedIn: boolean;
  isAdmin: boolean;
  isBank: boolean;
  token: string | null;
  user: any;
  loading: boolean;
  login: (token: string, user: UserType) => void;
  logout: () => void;
  checkValidationCode: (validationCode: string) => Promise<boolean>;
};

export const AuthContext = React.createContext<AuthContextType>({
  isLoggedIn: false,
  justLoggedIn: false,
  isAdmin: false,
  isBank: false,
  token: null,
  user: null,
  loading: false,
  login: () => {},
  logout: () => {},
  checkValidationCode: async () => false,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const login = (token: string, user: UserType) => {
    setToken(token);
    setUser(user);
    setIsLoggedIn(true);
    setIsAdmin(user.role === "Admin");
    setIsBank(user.role === "BankAccount");
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    setIsLoggedIn(false);
    setIsAdmin(false);
    setIsBank(false);
  };

  const checkValidationCode = async (validationCode: string) => {
    setLoading(true);
    const response = await loginMagicLink(validationCode);
    checkApiError(response.respStatus);
    if (response.respData) {
      setIsLoggedIn(true);
      setIsBank(true);
      setJustLoggedIn(true);
      setLoading(false);
      return true;
    } else {
      setIsLoggedIn(false);
      setIsBank(false);
      setLoading(false);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        justLoggedIn,
        isAdmin,
        isBank,
        token,
        user,
        loading,
        login,
        logout,
        checkValidationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
