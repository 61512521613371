import React, { useState, useEffect } from "react";
import { Button, Card, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Stepper from "../component/stepper/Stepper";
import {
  findFolderById,
  updateFolderById,
} from "../services/RealEstateFolder.service";
import styled from "styled-components";
import Review from "../component/review/Review";
import Modification from "../component/simulator/Modification";
import { FolderDetails, REFolderStatus } from "../types/REFolder.interface";
import { checkApiError } from "../apiCheckError";
import AccordionFiles from "../component/upload/AccordionFiles";

export const statusList = Object.values(REFolderStatus);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  min-height: 100vh;
`;

const StepperContainer = styled.div`
  width: 100%;
  padding: 16px 0;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
`;

const ThankYouCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-top: 16px;
`;

const FileUpload: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  const [folder, setFolder] = useState<FolderDetails>();
  const [currentStatus, setCurrentStatus] = useState<REFolderStatus>(
    REFolderStatus.AddingInformations,
  );
  const [showModification, setShowModification] = useState(false);
  const [renovationBudget, setRenovationBudget] = useState<number>(0);
  const [desiredContribution, setDesiredContribution] = useState<number>(0);

  useEffect(() => {
    const fetchCurrentStep = async () => {
      const response = await findFolderById(id!);
      checkApiError(response.respStatus);
      const folder = response.respData;
      setFolder(folder);
      const currentStatus = folder.status;
      const currentIndex = statusList.indexOf(currentStatus);
      setCurrentStep(currentIndex);
      setCurrentStatus(currentStatus);
      setRenovationBudget(folder.renovationBudget || 0);
      setDesiredContribution(folder.desiredContribution || 0);
    };

    fetchCurrentStep();
  }, [id]);

  const handleBackClick = () => {
    navigate(`/mes-dossiers`);
  };

  const handleStepClick = async (stepIndex: number) => {
    let newStatus = currentStatus;
    if (stepIndex === 0) {
      newStatus = REFolderStatus.AddingInformations;
    } else if (stepIndex === 1) {
      newStatus = REFolderStatus.Completion;
    }
    if (newStatus !== currentStatus) {
      checkApiError(
        (await updateFolderById(id!, { status: newStatus })).respStatus,
      );
      setCurrentStatus(newStatus);
    }
    setShowModification(stepIndex === 0);
    setCurrentStep(stepIndex);
  };

  const handleModifyClick = async () => {
    checkApiError(
      (
        await updateFolderById(id!, {
          status: REFolderStatus.AddingInformations,
        })
      ).respStatus,
    );
    setShowModification(true);
    setCurrentStatus(REFolderStatus.AddingInformations);
    setCurrentStep(0);
  };

  const handleGoToUploadStep = async () => {
    checkApiError(
      (await updateFolderById(id!, { status: REFolderStatus.Completion }))
        .respStatus,
    );
    setShowModification(false);
    setCurrentStatus(REFolderStatus.Completion);
    setCurrentStep(1);
  };

  const renderThankYouCard = () => {
    let message = "Un banquier va prendre contact avec vous pour la suite.";
    if (currentStatus === REFolderStatus.Done) {
      message =
        "Félicitations pour l'obtention de votre prêt ! Bonne chance dans votre projet.";
    }
    return (
      <ThankYouCard>
        <Typography.Title level={3}>Merci !</Typography.Title>
        <Typography.Paragraph>{message}</Typography.Paragraph>
        <Button type="primary" onClick={handleBackClick}>
          Retour aux dossiers
        </Button>
      </ThankYouCard>
    );
  };

  return (
    <>
      <Container>
        {[
          REFolderStatus.WaitingForBankAppointment,
          REFolderStatus.WaitingForOfferSignature,
          REFolderStatus.WaitingForNotaryAppointment,
          REFolderStatus.Done,
        ].includes(currentStatus) ? (
          renderThankYouCard()
        ) : (
          <>
            <StepperContainer>
              <Stepper
                currentStep={currentStep}
                onStepClick={handleStepClick}
              />
            </StepperContainer>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Content>
                  {showModification ||
                  currentStatus === REFolderStatus.AddingInformations ? (
                    <>
                      <Modification />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "16px",
                        }}
                      >
                        <Button
                          type="primary"
                          style={{ marginRight: "10px" }}
                          onClick={handleBackClick}
                        >
                          Retour
                        </Button>
                        <Button type="primary" onClick={handleGoToUploadStep}>
                          Suivant
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      {(currentStatus === REFolderStatus.Completion ||
                        currentStatus === REFolderStatus.Analysing) && (
                        <>
                          <AccordionFiles
                            renovationBudget={renovationBudget}
                            desiredContribution={desiredContribution}
                            folder={folder!}
                          />
                          {currentStatus === REFolderStatus.Completion && (
                            <Button
                              type="primary"
                              style={{ marginTop: "16px" }}
                              onClick={handleModifyClick}
                            >
                              Modifier
                            </Button>
                          )}
                        </>
                      )}
                      {(currentStatus === REFolderStatus.DocumentVerification ||
                        currentStatus === REFolderStatus.Auction ||
                        currentStatus === REFolderStatus.Auctioned) && (
                        <Review folderId={id!} />
                      )}
                    </>
                  )}
                </Content>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default FileUpload;
