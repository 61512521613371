import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Card, Spin, Row, Col } from "antd";
import styled from "styled-components";
import { findBankFolderById } from "../../services/RealEstateFolder.service";
import { getUserById } from "../../services/users.service";
import { UserType } from "../../types";
import {
  BidProposition,
  FolderDetails,
  BidStatus,
} from "../../types/REFolder.interface";
import { useAuth } from "../../authentification/auth.hook";
import {
  EuroOutlined,
  PercentageOutlined,
  CalendarOutlined,
  BankOutlined,
  HomeOutlined,
  FileTextOutlined,
  AimOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { MAIN_COLOR, BACKGROUND_COLOR } from "../../utils";
import FileInformations from "../component/folderDetails/FolderInformations";
import BidForm from "../component/folderDetails/BidForm";
import BidWon from "../component/folderDetails/BidWon";
import { checkApiError } from "../../apiCheckError";

const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const InfoText = styled.p`
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const OfferCard = styled(Card)`
  margin-top: 16px;
  font-size: 18px;
  background-color: ${BACKGROUND_COLOR};
  border: 1px solid ${MAIN_COLOR};

  .ant-card-head-title {
    font-size: 20px;
    color: white;
  }

  .offer-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .anticon {
      margin-right: 8px;
      font-size: 20px;
      color: ${MAIN_COLOR};

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
`;

const BidContainerCard = styled(Card)`
  margin-top: 24px;
  width: 100%;

  .ant-card-head-title {
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

const FolderDetailsBank: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user: currentUser } = useAuth();
  const [folder, setFolder] = useState<FolderDetails | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState(true);
  const [bidPropositions, setBidPropositions] = useState<BidProposition[]>([]);
  const [isBidWon, setIsBidWon] = useState(false);

  const fetchUserById = async (userId: string) => {
    const userResponse = await getUserById(userId);
    checkApiError(userResponse.respStatus);
    return userResponse.respData;
  };

  useEffect(() => {
    const fetchDetails = async () => {
      if (!id) return;

      const folderResponse = await findBankFolderById(id);
      checkApiError(folderResponse.respStatus);
      const fetchedFolder = folderResponse.respData;

      if (fetchedFolder) {
        setFolder(fetchedFolder);

        if (fetchedFolder.userId) {
          const fetchedUser = await fetchUserById(fetchedFolder.userId);
          setUser(fetchedUser);
        }

        if (fetchedFolder.bidInfos && currentUser) {
          const userBidInfos = fetchedFolder.bidInfos.filter(
            (bidInfo) => bidInfo.bankUserId === currentUser.id,
          );
          const bidWonStatus = userBidInfos.some(
            (bidInfo) => bidInfo.status === BidStatus.BidWon,
          );
          setIsBidWon(bidWonStatus);
          setBidPropositions(
            userBidInfos.flatMap((bidInfo) => bidInfo.bidPropositions),
          );
        }
      }
      setLoading(false);
    };

    fetchDetails();
  }, [id, currentUser]);

  const memoizedUser = useMemo(() => user, [user]);
  const memoizedFolder = useMemo(() => folder, [folder]);
  const memoizedBidPropositions = useMemo(
    () => bidPropositions,
    [bidPropositions],
  );

  if (loading) {
    return <Spin />;
  }

  if (isBidWon) {
    return (
      <BidWon
        folder={memoizedFolder}
        user={memoizedUser}
        bidPropositions={memoizedBidPropositions}
      />
    );
  }

  return (
    <Container>
      <Row gutter={[14, 14]}>
        <Col xs={24} lg={12}>
          <FileInformations folder={memoizedFolder} user={memoizedUser} />
        </Col>
        <Col xs={24} lg={12}>
          {memoizedFolder && (
            <BidForm
              folderId={id}
              setBidPropositions={setBidPropositions}
              bidPropositions={memoizedBidPropositions}
              amount={memoizedFolder.propertyPrice}
              rate={4}
              durationInYear={memoizedFolder.loanDuration}
              contribution={memoizedFolder.desiredContribution}
            />
          )}
        </Col>
      </Row>
      <BidContainerCard title="Mes enchères">
        <Row gutter={[16, 16]}>
          {memoizedBidPropositions.map((bid, index) => (
            <Col key={index} xs={24} sm={12} md={12} lg={12}>
              <OfferCard title={`Enchère ${index + 1}`}>
                <Row>
                  <Col span={24} md={12}>
                    <div className="offer-info">
                      <EuroOutlined />
                      <InfoText>
                        <BoldText>Montant : </BoldText>
                        {bid.amount} €
                      </InfoText>
                    </div>
                    <div className="offer-info">
                      <PercentageOutlined />
                      <InfoText>
                        <BoldText>Taux : </BoldText>
                        {bid.rate} %
                      </InfoText>
                    </div>
                    <div className="offer-info">
                      <CalendarOutlined />
                      <InfoText>
                        <BoldText>Durée : </BoldText>
                        {bid.durationInYear} ans
                      </InfoText>
                    </div>
                    <div className="offer-info">
                      <BankOutlined />
                      <InfoText>
                        <BoldText>Apport : </BoldText>
                        {bid.contribution} €
                      </InfoText>
                    </div>
                  </Col>
                  <Col span={24} md={12}>
                    <div className="offer-info">
                      <AimOutlined />
                      <InfoText>
                        <BoldText>Domiciliation des revenus : </BoldText>
                        {bid.domiciliationOfIncomes ? "Oui" : "Non"}
                      </InfoText>
                    </div>
                    <div className="offer-info">
                      <FileDoneOutlined />
                      <InfoText>
                        <BoldText>Domiciliation de l'épargne : </BoldText>
                        {bid.domiciliationOfSavings ? "Oui" : "Non"}
                      </InfoText>
                    </div>
                    <div className="offer-info">
                      <HomeOutlined />
                      <InfoText>
                        <BoldText>Assurance habitation : </BoldText>
                        {bid.homeInsurance ? "Oui" : "Non"}
                      </InfoText>
                    </div>
                    {bid.otherConditions && (
                      <div className="offer-info">
                        <FileTextOutlined />
                        <InfoText>
                          <BoldText>Autres conditions : </BoldText>
                          {bid.otherConditions}
                        </InfoText>
                      </div>
                    )}
                  </Col>
                </Row>
              </OfferCard>
            </Col>
          ))}
        </Row>
      </BidContainerCard>
    </Container>
  );
};

export default FolderDetailsBank;
