import { Col, Form, Input, Row, theme } from "antd";
import React from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  HOOVER_COLOR,
  MAIN_COLOR,
} from "../../utils";

const { Item: FormItem } = Form;
const { useToken } = theme;

const SearchWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;

  .ant-input-affix-wrapper {
    flex: 1;
    background-color: ${({ theme }) => theme.colorBgBase};
    color: ${({ theme }) => theme.colorTextBase};
    border: 1px solid ${({ theme }) => theme.colorBorder};
    border-radius: 4px;
    transition: border-color 0.3s;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colorBorder};
  }

  .ant-input {
    color: ${({ theme }) => theme.colorTextBase};
  }

  .anticon {
    color: ${({ theme }) => theme.colorTextBase};
  }
`;

const CustomWrapper = styled.div`
  width: 100%;
  max-width: 740px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CustomFormItem = styled(FormItem)`
  margin-bottom: 8px;

  .ant-form-item-label > label {
    color: ${({ theme }) => theme.colorTextBase};
  }

  .ant-form-item-control-input {
    .ant-input {
      background-color: ${({ theme }) => theme.colorBgBase};
      color: ${({ theme }) => theme.colorTextBase};
      border: 1px solid ${({ theme }) => BORDER_COLOR};
      border-radius: 4px;
      transition: border-color 0.3s;

      &:hover {
        border-color: ${({ theme }) => MAIN_COLOR};
      }
    }
  }
`;

interface AddressFormValues {
  address: {
    num?: string;
    street?: string;
    zip_code?: string;
    city?: string;
    country?: string;
  };
}

const CustomPlacesAutocomplete = ({
  value,
  onChange,
}: {
  value: any;
  onChange: (value: any) => void;
}) => {
  const { token } = useToken();
  return (
    <CustomWrapper>
      <PlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        selectProps={{
          value,
          onChange,
          placeholder: "Rechercher ...",
          styles: {
            control: (provided: any) => ({
              ...provided,
              backgroundColor: BACKGROUND_COLOR,
              border: `1px solid ${token.colorBorder}`,
              borderRadius: "4px",
              color: token.colorTextBase,
            }),
            input: (provided: any) => ({
              ...provided,
              color: token.colorTextBase,
            }),
            singleValue: (provided: any) => ({
              ...provided,
              backgroundColor: BACKGROUND_COLOR,
              color: token.colorTextBase,
            }),
            menu: (provided: any) => ({
              ...provided,
              backgroundColor: BACKGROUND_COLOR,
            }),
            placeholder: (provided: any) => ({
              ...provided,
              backgroundColor: BACKGROUND_COLOR,
              color: token.colorTextBase,
            }),
            option: (provided: any, state: any) => ({
              ...provided,
              backgroundColor: state.isFocused
                ? HOOVER_COLOR
                : BACKGROUND_COLOR,
              color: state.isSelected ? token.colorTextBase : token.colorText,
            }),
          },
        }}
      />
    </CustomWrapper>
  );
};

const AddressForm: React.FC = () => {
  const { control, setValue, trigger } = useFormContext<AddressFormValues>();

  const handleSelect = async (option: any) => {
    const results = await geocodeByPlaceId(option.value.place_id);
    const addressComponents = results[0].address_components;

    const address = {
      streetNumber: "",
      route: "",
      postalCode: "",
      locality: "",
      country: "",
    };

    addressComponents.forEach((component: any) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("street_number")) {
        address.streetNumber = value;
      } else if (types.includes("route")) {
        address.route = value;
      } else if (types.includes("postal_code")) {
        address.postalCode = value;
      } else if (types.includes("locality")) {
        address.locality = value;
      } else if (types.includes("country")) {
        address.country = value;
      }
    });

    setValue("address.num", address.streetNumber || "");
    setValue("address.street", address.route || "");
    setValue("address.zip_code", address.postalCode || "");
    setValue("address.city", address.locality || "");
    setValue("address.country", address.country || "");

    await trigger("address");
  };

  return (
    <div>
      <CustomFormItem label="Adresse">
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <SearchWrapper>
              <CustomPlacesAutocomplete
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  handleSelect(value);
                }}
              />
            </SearchWrapper>
          )}
        />
      </CustomFormItem>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={6}>
          <CustomFormItem label="Num">
            <Controller
              name="address.num"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} />}
            />
          </CustomFormItem>
        </Col>
        <Col xs={24} md={18}>
          <CustomFormItem label="Rue">
            <Controller
              name="address.street"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} />}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={6}>
          <CustomFormItem label="CP">
            <Controller
              name="address.zip_code"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} />}
            />
          </CustomFormItem>
        </Col>
        <Col xs={24} md={6}>
          <CustomFormItem label="Ville">
            <Controller
              name="address.city"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} />}
            />
          </CustomFormItem>
        </Col>
        <Col xs={24} md={6}>
          <CustomFormItem label="Pays">
            <Controller
              name="address.country"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} />}
            />
          </CustomFormItem>
        </Col>
      </Row>
    </div>
  );
};

export default AddressForm;
