// paths & errors
import {
  ToLoginType,
  LoginType,
  UserType,
  ToValidForgetPassword,
  SignUpType,
  UserBankType,
} from "../types";
import {
  API_ASK_MAGIC_LINK,
  API_ASK_RESET_PASSWORD,
  API_CONFIRM_EMAIL,
  API_LOGIN,
  API_LOGOUT,
  API_MAGIC_LINK,
  API_RESET_PASSWORD,
  API_SIGNUP,
  API_UPDATE_EMAIL,
  API_UPDATE_INFOS,
  API_UPDATE_PASSWORD,
  API_URL,
  API_USERS,
} from "./ApiPaths";

// axios

/*****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

export const loginMagicLink = async (
  validationCode: string,
): Promise<{ respData: LoginType; respStatus: number }> => {
  const response = await fetch(API_URL + API_LOGIN + API_MAGIC_LINK, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ validationCode }),
  });

  const responseData = await response.json();
  const responseStatus = response.status;

  if (responseData.user.admin_role) {
    delete responseData.user.admin_role;
  }

  localStorage.setItem("reeadyid", responseData.token);
  localStorage.setItem("user", JSON.stringify(responseData.user));
  window.dispatchEvent(new Event("storage"));

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const askMagicLink = async (
  mail: string,
): Promise<{ respStatus: number }> => {
  const response = await fetch(API_URL + API_LOGIN + API_ASK_MAGIC_LINK, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: mail }),
  });

  const responseStatus = response.status;

  return {
    respStatus: responseStatus,
  };
};

export const loginForm = async (
  data: ToLoginType,
): Promise<{ respData: LoginType; respStatus: number }> => {
  const response = await fetch(API_URL + API_LOGIN, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  });

  const responseData = await response.json();
  const responseStatus = response.status;

  if (!responseData.user.admin_role) {
    delete responseData.user.admin_role;
  }

  localStorage.setItem("reeadyid", responseData.token);
  localStorage.setItem("user", JSON.stringify(responseData.user));
  window.dispatchEvent(new Event("storage"));

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

function clearLocalStorage() {
  localStorage.removeItem("reeadyid");
  localStorage.removeItem("user");
}

/**
 * Logout through the API
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_UNAUTHORIZED
 * @error ERROR_UNKNOWN
 */
export const logout = async (): Promise<{
  respData: void;
  respStatus: number;
}> => {
  const response = await fetch(API_URL + API_LOGOUT, {
    credentials: "include",
  });
  clearLocalStorage();
  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

/**
 * Confirm the email of a user with a code (sent by email)
 * @param userId
 * @param code
 * @error ERROR_CODE_INVALID
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const confirmEmail = async (userId: string, code: string) => {
  const response = await fetch(
    API_URL + API_CONFIRM_EMAIL.replace(":id", userId),
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: code,
      }),
    },
  );
  const responseData = await response.json();
  const responseStatus = response.status;

  const token: string = responseData.token;
  const user: UserType = responseData.user;

  localStorage.setItem("reeadyid", token);
  localStorage.setItem("user", JSON.stringify(user));
  window.dispatchEvent(new Event("storage"));

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

/**
 *
 * @param email
 * @error ERR_EMAIL_ALREADY_TAKEN
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updateEmail = async (
  email: string,
): Promise<{ respData: UserType; respStatus: number }> => {
  const response = await fetch(API_URL + API_UPDATE_EMAIL, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  });
  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const updateUserInfos = async (
  data: UserType,
): Promise<{ respData: UserType; respStatus: number }> => {
  const response = await fetch(API_URL + API_UPDATE_INFOS, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      firstname: data.firstname,
      lastname: data.lastname,
      currentBank: data.currentBank,
    }),
  });
  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

/**
 * Change user's password
 * @param currentPassword
 * @param newPassword
 * @error ERROR_INVALID_CREDENTIALS
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updatePassword = async (
  currentPassword: string,
  newPassword: string,
): Promise<{ respData: UserType; respStatus: number }> => {
  const response = await fetch(API_URL + API_UPDATE_PASSWORD, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      current_password: currentPassword,
      new_password: newPassword,
    }),
  });

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

/**
 * It initiates the reset password process. The user will receive a mail with the reset_UUID.
 * @param email account email
 * @error ERROR_USER_DO_NO_EXIST
 * @error ERROR_UNKNOWN
 */
export const askResetPassword = async (
  data: ToValidForgetPassword,
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(API_URL + API_ASK_RESET_PASSWORD, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ ...data }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return { respData: responseData, respStatus: responseStatus };
};

/**
 * It allows users to update their password with the correct uuid they received by mail.
 * @param reset_uuid
 * @param password new password
 * @error ERROR_RESET_EXPIRED
 * @error ERROR_RESET_WRONG_UUID
 * @error ERROR_UNKNOWN
 */
export const resetPassword = async (
  password: string,
  reset_uuid: string,
): Promise<{ respData: UserType; respStatus: number }> => {
  const response = await fetch(API_URL + API_RESET_PASSWORD, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      reset_uuid,
      new_password: password,
    }),
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return { respData: responseData, respStatus: responseStatus };
};

export const signUpApi = async (data: SignUpType) => {
  const newData = { ...data };
  delete newData.confirm_password;
  const response = await fetch(API_URL + API_USERS + API_SIGNUP, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newData),
  });
  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respdata: responseData,
    respstatus: responseStatus,
  };
};

export const getUserById = async (
  id: string,
): Promise<{ respData: UserType; respStatus: number }> => {
  const url = API_URL + API_USERS + "/" + id;
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const getBankUsers = async (): Promise<{
  respData: UserBankType[];
  respStatus: number;
}> => {
  const url = API_URL + "/users?role=BankAccount";
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const newBankAccount = async (
  email: string,
): Promise<{ respData: UserBankType; respStatus: number }> => {
  const data = { email };
  const response = await fetch(API_URL + "/users/new-bank-account", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};
