import React, { useState, useEffect } from "react";
import { Typography, Tabs, Badge, Card, Spin } from "antd";
import { SolutionOutlined, ClockCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  HOOVER_COLOR,
  REAL_BACKGROUND_COLOR,
  MAIN_COLOR,
  BANK_ICON_2_COLOR,
  BANK_ICON_3_COLOR,
} from "../../utils";
import { getBankFolders } from "../../services/RealEstateFolder.service";
import { getUserById } from "../../services/users.service";
import {
  BidStatus,
  BidInfo,
  FolderDetails,
  REFolderStatus,
} from "../../types/REFolder.interface";
import { UserType } from "../../types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authentification/auth.hook";
import { checkApiError } from "../../apiCheckError";
import {
  BANK_TIMER_1_WEEK,
  BANK_TIMER_24_HOURS,
  BANK_TIMER_48_HOURS,
} from "../component/timers/BankTimers";

const { Title } = Typography;
const { TabPane } = Tabs;

const Container = styled.div`
  padding: 20px;
  width: 100%;
  text-align: center;
  min-height: 100vh !important;
`;

const LogoTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ant-tabs-tab {
    background-color: ${REAL_BACKGROUND_COLOR};
    color: white;
    border: 1px solid ${REAL_BACKGROUND_COLOR};
    margin: 0 8px;
    padding: 8px 16px;
    border-bottom: 1px solid ${HOOVER_COLOR};
    font-size: 18px;
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LargeBadge = styled(Badge)`
  .ant-badge-count {
    font-size: 16px;
    height: 20px;
    min-width: 24px;
  }
`;

const Description = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: white;
  padding-bottom: 20px;
`;

const InfoText = styled.p`
  font-size: 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const TitleWithTimer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TimerText = styled(BoldText)`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto; /* This will push the timer to the right */
`;

const calculateRemainingTime = (endTime: Date) => {
  const now = new Date();
  const diffMs = endTime.getTime() - now.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  const remainingMinutes = diffMinutes % 60;
  const remainingHours = diffHours % 24;

  if (diffMs <= 0) return "Temps écoulé";

  return `${diffDays}j ${remainingHours}h ${remainingMinutes}m`;
};

const HomepageBank: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("1");
  const [, setFolders] = useState<FolderDetails[]>([]);
  const [waitingForOffer, setWaitingForOffer] = useState<FolderDetails[]>([]);
  const [waitingForClientFeedback, setWaitingForClientFeedback] = useState<
    FolderDetails[]
  >([]);
  const [bidWon, setBidWon] = useState<FolderDetails[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchFolders = async () => {
      const foldersResponse = await getBankFolders();
      checkApiError(foldersResponse.respStatus);
      const fetchedFolders = foldersResponse.respData;
      setFolders(fetchedFolders);

      if (user) {
        setWaitingForOffer(
          fetchedFolders.filter(
            (folder) =>
              folder.bidInfos &&
              folder.status === REFolderStatus.Auctioned &&
              folder.bidInfos.some(
                (bidInfo: BidInfo) =>
                  bidInfo.status === BidStatus.WaitingForOffer &&
                  bidInfo.active &&
                  bidInfo.bankUserId === user.id,
              ),
          ),
        );

        setWaitingForClientFeedback(
          fetchedFolders.filter(
            (folder) =>
              folder.bidInfos &&
              folder.status === REFolderStatus.Auctioned &&
              folder.bidInfos.some(
                (bidInfo: BidInfo) =>
                  bidInfo.status === BidStatus.WaitingForClientFeedback &&
                  bidInfo.active &&
                  bidInfo.bankUserId === user.id,
              ),
          ),
        );

        setBidWon(
          fetchedFolders.filter(
            (folder) =>
              folder.bidInfos &&
              folder.status !== REFolderStatus.Auction &&
              folder.bidInfos.some(
                (bidInfo: BidInfo) =>
                  bidInfo.status === BidStatus.BidWon &&
                  bidInfo.active &&
                  bidInfo.bankUserId === user.id,
              ),
          ),
        );
      }
    };

    fetchFolders();
  }, [user]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const getDescription = () => {
    switch (activeTab) {
      case "1":
        return "Les clients attendent vos proposition";
      case "2":
        return "En attente des décisions des clients";
      case "3":
        return "Félicitations ! Voici vos enchères remportées";
      default:
        return "";
    }
  };

  const renderFolderCards = (folders: FolderDetails[]) =>
    folders.map((folder) => <FolderCard key={folder.id} folder={folder} />);

  return (
    <Container>
      <LogoTitleContainer>
        <SolutionOutlined style={{ fontSize: "32px", color: MAIN_COLOR }} />
        <Title level={2} style={{ color: "white" }}>
          Mes enchères
        </Title>
      </LogoTitleContainer>
      <StyledTabs activeKey={activeTab} onChange={handleTabChange} centered>
        <TabPane
          tab={
            <TabContent>
              <LargeBadge
                count={waitingForOffer.length}
                style={{ backgroundColor: MAIN_COLOR, marginRight: "8px" }}
              />
              En attente d'une offre
            </TabContent>
          }
          key="1"
        >
          <Description>{getDescription()}</Description>
          {renderFolderCards(waitingForOffer)}
        </TabPane>
        <TabPane
          tab={
            <TabContent>
              <LargeBadge
                count={waitingForClientFeedback.length}
                style={{
                  backgroundColor: BANK_ICON_2_COLOR,
                  marginRight: "8px",
                }}
              />
              Attente du retour client
            </TabContent>
          }
          key="2"
        >
          <Description>{getDescription()}</Description>
          {renderFolderCards(waitingForClientFeedback)}
        </TabPane>
        <TabPane
          tab={
            <TabContent>
              <LargeBadge
                count={bidWon.length}
                style={{
                  backgroundColor: BANK_ICON_3_COLOR,
                  marginRight: "8px",
                }}
              />
              Enchères remportées
            </TabContent>
          }
          key="3"
        >
          <Description>{getDescription()}</Description>
          {renderFolderCards(bidWon)}
        </TabPane>
      </StyledTabs>
    </Container>
  );
};

const FolderCard: React.FC<{ folder: FolderDetails }> = ({ folder }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState<string>("Calcul en cours...");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const userResponse = await getUserById(folder.userId);
      checkApiError(userResponse.respStatus);
      const fetchedUser = userResponse.respData;
      setUser(fetchedUser);
      setLoading(false);
    };

    const calculateTimer = () => {
      const lastStatus = folder.bidInfos?.[0].historyStatuses.slice(-1)[0];

      if (lastStatus) {
        const createdAt = new Date(lastStatus.created_at);
        let endTime = createdAt;

        //Change timers if needed
        switch (lastStatus.status) {
          case BidStatus.WaitingForOffer:
            endTime.setHours(createdAt.getHours() + BANK_TIMER_24_HOURS);
            break;
          case BidStatus.WaitingForClientFeedback:
            endTime.setDate(createdAt.getDate() + BANK_TIMER_1_WEEK);
            break;
          case BidStatus.BidWon:
            endTime.setHours(createdAt.getHours() + BANK_TIMER_48_HOURS);
            break;
          default:
            break;
        }

        setTimer(calculateRemainingTime(endTime));
      }
    };

    fetchUser();
    calculateTimer();

    const interval = setInterval(() => {
      calculateTimer();
    }, 60000);

    return () => clearInterval(interval);
  }, [folder.userId, folder.bidInfos]);

  const handleClick = () => {
    navigate(`/banque/mes-dossiers/${folder.id}`);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Card
      title={
        <TitleWithTimer>
          <span>{`${user?.firstname} ${user?.lastname} (${user?.email})`}</span>
          <TimerText>
            <ClockCircleOutlined style={{ color: MAIN_COLOR }} />
            {timer}
          </TimerText>
        </TitleWithTimer>
      }
      style={{ marginBottom: 16, width: "100%", cursor: "pointer" }}
      onClick={handleClick}
    >
      <InfoText>
        <BoldText>Type de bien : </BoldText>
        {folder.propertyType}
      </InfoText>
      <InfoText>
        <BoldText>Condition : </BoldText>
        {folder.condition}
      </InfoText>
      <InfoText>
        <BoldText>Prix : </BoldText>
        {folder.propertyPrice} €
      </InfoText>
      <InfoText>
        <BoldText>Adresse : </BoldText>
        {folder.address}
      </InfoText>
      <InfoText>
        <BoldText>Durée du prêt : </BoldText>
        {folder.loanDuration} ans
      </InfoText>
    </Card>
  );
};

export default HomepageBank;
