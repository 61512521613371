import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../authentification/auth.hook";
import NavbarAdmin from "./component/navbar/NavbarBackoffice";

const ProtectedAdminRoute = () => {
  const location = useLocation();
  const { isAdmin } = useAuth();

  return isAdmin ? (
    <>
      <NavbarAdmin />
      <Outlet />
    </>
  ) : (
    <Navigate to="/404error" state={{ from: location }} replace />
  );
};

export default ProtectedAdminRoute;
