import { useContext } from "react";
import { AuthContext } from "./AuthContext";

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error(
      "useAuth doit être utilisé à l'intérieur d'un AuthProvider",
    );
  }
  return auth;
};
