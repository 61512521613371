import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../authentification/auth.hook";
import Sidebar from "./component/sidebar/Sidebar";
import { Layout, Spin } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";

const { Content } = Layout;

const FullHeightLayout = styled(Layout)`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled(Content)`
  padding: 20px;
  flex: 1;
`;

const CenteredSpinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ProtectedBankRoute = () => {
  const location = useLocation();
  const { isBank, loading, checkValidationCode, isLoggedIn, justLoggedIn } =
    useAuth();
  const validationCode = new URLSearchParams(location.search).get(
    "validationCode",
  );
  const [checkComplete, setCheckComplete] = useState<boolean>(false);

  useEffect(() => {
    const validateCode = async () => {
      if (validationCode && !isLoggedIn) {
        await checkValidationCode(validationCode);
      }
      setCheckComplete(true);
    };
    validateCode();
  }, [validationCode, checkValidationCode, isLoggedIn]);

  if (loading || !checkComplete) {
    return <CenteredSpinner size="large" />;
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/banque/authentification"
        state={{ from: location }}
        replace
      />
    );
  }

  return isBank || justLoggedIn ? (
    <FullHeightLayout>
      <Sidebar />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </FullHeightLayout>
  ) : (
    <Navigate to="/404error" state={{ from: location }} replace />
  );
};

export default ProtectedBankRoute;
