import { checkApiError } from "../../apiCheckError";
import { updateFolderById } from "../../services/RealEstateFolder.service";
import {
  Justificative,
  FileStatus,
  InvestmentType,
  Condition,
} from "../../types/REFolder.interface";

export const generateProjectDocuments = async (
  residenceType: boolean,
  propertyCondition: boolean,
  renovationBudget: number,
  folderId: string,
): Promise<Justificative[]> => {
  let projectDocuments: Justificative[] = [
    {
      name: "compromis_vente_project",
      title: "Compromis de vente",
      description: "Compromis de vente",
      validityState: FileStatus.Empty,
    },
    {
      name: "dpe_project",
      title: "Diagnostic de performance énergétique",
      description: "Diagnostic de performance énergétique",
      validityState: FileStatus.Empty,
    },
  ];

  if (residenceType) {
    checkApiError(
      (
        await updateFolderById(folderId, {
          investmentType: InvestmentType.MainResidence,
        })
      ).respStatus,
    );
  } else {
    checkApiError(
      (
        await updateFolderById(folderId, {
          investmentType: InvestmentType.RentalProperty,
        })
      ).respStatus,
    );
  }

  if (propertyCondition) {
    checkApiError(
      (await updateFolderById(folderId, { condition: Condition.New }))
        .respStatus,
    );
  } else {
    checkApiError(
      (await updateFolderById(folderId, { condition: Condition.Old }))
        .respStatus,
    );
  }

  if (renovationBudget > 0) {
    projectDocuments.push({
      name: "devis_travaux_project",
      title: "Devis de travaux",
      description: "Devis de travaux",
      validityState: FileStatus.Empty,
    });
  }

  if (!residenceType) {
    projectDocuments.push(
      {
        name: "baux_location_project",
        title: "Baux de location",
        description: "Baux de location",
        validityState: FileStatus.Empty,
      },
      {
        name: "estimation_locative_project",
        title: "Estimation locative",
        description: "Estimation locative",
        validityState: FileStatus.Empty,
      },
    );
  }

  if (propertyCondition) {
    projectDocuments.push(
      {
        name: "contrat_construction_project",
        title: "Contrat de construction",
        description: "Contrat de construction",
        validityState: FileStatus.Empty,
      },
      {
        name: "devis_project",
        title: "Devis",
        description: "Devis",
        validityState: FileStatus.Empty,
      },
      {
        name: "recepisse_permis_project",
        title: "Recepissé du permis de construire",
        description: "Recepissé du permis de construire",
        validityState: FileStatus.Empty,
      },
      {
        name: "plans_project",
        title: "Plans",
        description: "Plans",
        validityState: FileStatus.Empty,
      },
      {
        name: "assurances_project",
        title: "Assurances",
        description: "Assurances",
        validityState: FileStatus.Empty,
      },
    );
  }

  return projectDocuments;
};
