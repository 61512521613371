import { checkApiError } from "../../apiCheckError";
import { updateFolderById } from "../../services/RealEstateFolder.service";
import { Justificative, FileStatus } from "../../types/REFolder.interface";

const createJustificative = (
  name: string,
  title: string,
  personNumber: number,
): Justificative => {
  return {
    name: `person_${personNumber}_${name}_income`,
    title: `${title}`,
    description: `${title}`,
    validityState: FileStatus.Empty,
  };
};

export const generateIncomeDocuments = async (
  numPeople: number,
  employee: boolean,
  cafBeneficiary: boolean,
  loansInProgress: boolean,
  desiredContribution: number,
  folderId: string,
): Promise<Justificative[]> => {
  let updatedIncomes: Justificative[] = [];

  checkApiError(
    (
      await updateFolderById(folderId, {
        numPeople: numPeople,
        isEmployed: employee,
        hasCAF: cafBeneficiary,
        hasOngoingLoans: loansInProgress,
      })
    ).respStatus,
  );

  for (let i = 1; i <= numPeople; i++) {
    let baseIncomes = [
      createJustificative("avis_imposition_1", "Avis d'imposition N-1", i),
      createJustificative("avis_imposition_2", "Avis d'imposition N-2", i),
      createJustificative("releve_epargne", "Dernier relevé d'épargne", i),
    ];

    if (employee) {
      baseIncomes.push(
        createJustificative("bulletin_salaire_1", "Bulletin de salaire M-1", i),
      );
      baseIncomes.push(
        createJustificative("bulletin_salaire_2", "Bulletin de salaire M-2", i),
      );
      baseIncomes.push(
        createJustificative("bulletin_salaire_3", "Bulletin de salaire M-3", i),
      );
      baseIncomes.push(
        createJustificative(
          "bulletin_salaire_decembre",
          "Bulletin de salaire de décembre N-1",
          i,
        ),
      );
      baseIncomes.push(
        createJustificative("contrat_travail", "Contrat de travail", i),
      );
    }

    if (cafBeneficiary) {
      baseIncomes.push(
        createJustificative("justificatif_caf", "Justificatif CAF", i),
      );
    }

    if (loansInProgress) {
      baseIncomes.push(
        createJustificative(
          "tableau_amortissement",
          "Tableau d'amortissement des prêts en cours",
          i,
        ),
      );
    }

    if (desiredContribution > 0) {
      baseIncomes.push(
        createJustificative(
          "justificatif_apport",
          "Justificatif de l'apport",
          i,
        ),
      );
    }

    updatedIncomes.push(...baseIncomes);
  }

  return updatedIncomes;
};
