import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { signUpApi } from "../../services/users.service";
import { PartnerBanks, SignUpType } from "../../types";
import { BACKGROUND_COLOR } from "../../utils";
import { checkApiError } from "../../apiCheckError";

const { Option } = Select;

export default function SignupCard() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<SignUpType>();

  const password = watch("password");

  const onSubmit = async (data: SignUpType) => {
    setIsSubmitting(true);
    setApiError(null);
    const response = await signUpApi(data);
    checkApiError(response.respstatus);
    const id = response.respdata.id;
    setIsSubmitting(false);
    navigate(`/inscription/${id}/validation`);
    message.info(
      "Un email vous a été envoyé, merci d'entrer ci dessous le code de validation.",
    );
  };

  return (
    <div
      style={{
        backgroundColor: BACKGROUND_COLOR,
        padding: "24px",
        borderRadius: "8px",
        maxWidth: "400px",
        margin: "auto",
      }}
    >
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        {apiError && (
          <div style={{ color: "red", marginBottom: "24px" }}>{apiError}</div>
        )}
        <Form.Item
          label="Prénom"
          validateStatus={errors.firstname ? "error" : ""}
          help={errors.firstname ? errors.firstname.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="firstname"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                prefix={<UserOutlined />}
                placeholder="Prénom"
              />
            )}
            rules={{ required: "Le prénom est obligatoire" }}
          />
        </Form.Item>

        <Form.Item
          label="Nom"
          validateStatus={errors.lastname ? "error" : ""}
          help={errors.lastname ? errors.lastname.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="lastname"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                prefix={<UserOutlined />}
                placeholder="Nom"
              />
            )}
            rules={{ required: "Le nom est obligatoire" }}
          />
        </Form.Item>

        <Form.Item
          label="Email"
          validateStatus={errors.email ? "error" : ""}
          help={errors.email ? errors.email.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                prefix={<UserOutlined />}
                placeholder="Email"
              />
            )}
            rules={{
              required: "L'adresse email est obligatoire",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "L'adresse email n'est pas valide",
              },
            }}
          />
        </Form.Item>

        <Form.Item
          label="Mot de passe"
          validateStatus={errors.password ? "error" : ""}
          help={errors.password ? errors.password.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input.Password
                {...field}
                size="large"
                prefix={<LockOutlined />}
                placeholder="Mot de passe"
              />
            )}
            rules={{
              required: "Le mot de passe est obligatoire",
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                message:
                  "Le mot de passe doit contenir au moins une majuscule, un chiffre, un caractère spécial et au moins 8 caractères.",
              },
            }}
          />
        </Form.Item>

        <Form.Item
          label="Confirmer le mot de passe"
          validateStatus={errors.confirm_password ? "error" : ""}
          help={errors.confirm_password ? errors.confirm_password.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="confirm_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input.Password
                {...field}
                size="large"
                prefix={<LockOutlined />}
                placeholder="Confirmez le mot de passe"
              />
            )}
            rules={{
              required: "La confirmation du mot de passe est obligatoire",
              validate: (value) =>
                value === password || "Les mots de passe ne correspondent pas",
            }}
          />
        </Form.Item>

        <Form.Item
          label="Banque actuelle (optionnel)"
          validateStatus={errors.currentBank ? "error" : ""}
          help={errors.currentBank ? errors.currentBank.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="currentBank"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                size="large"
                placeholder="Sélectionnez votre banque"
                allowClear
              >
                {Object.values(PartnerBanks).map((bank) => (
                  <Option key={bank} value={bank}>
                    {bank}
                  </Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isSubmitting}
            style={{ marginTop: "24px" }}
          >
            Créer un compte
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
