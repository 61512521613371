import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Card, Spin, Alert, Row, Col, Button } from "antd";
import {
  HomeOutlined,
  CheckCircleOutlined,
  PercentageOutlined,
  EuroCircleOutlined,
  BuildOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { findAdminFolderById } from "../../services/RealEstateFolder.service";
import { getUserById } from "../../services/users.service";
import AccordionBackoffice from "../component/files/AccordionBackoffice";
import ReviewBackoffice from "../component/files/ReviewBackoffice";
import BankMail from "../component/files/BankMail";
import { FolderDetails, REFolderStatus } from "../../types/REFolder.interface";
import { UserType } from "../../types";
import { MAIN_COLOR } from "../../utils";
import styled from "styled-components";
import { checkApiError } from "../../apiCheckError";

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const StyledBoldText = styled(StyledText)`
  font-weight: bold;
`;

const formatNumber = (number: number) => {
  return number.toLocaleString("fr-FR");
};

const FolderDetailsBackoffice: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [folderDetails, setFolderDetails] = useState<FolderDetails | null>(
    null,
  );
  const [userDetails, setUserDetails] = useState<UserType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error] = useState<string | null>(null);

  useEffect(() => {
    const fetchDetails = async () => {
      const folderResponse = await findAdminFolderById(id!);
      checkApiError(folderResponse.respStatus);
      const folderData = folderResponse.respData;
      setFolderDetails(folderData);

      const userResponse = await getUserById(folderData.userId);
      const userData = userResponse.respData;
      checkApiError(userResponse.respStatus);
      setUserDetails(userData);

      setLoading(false);
    };

    fetchDetails();
  }, [id]);

  const handleBackClick = () => {
    navigate("/backoffice/dossiers");
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "24px" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "24px" }}>
        <Alert message="Erreur" description={error} type="error" showIcon />
      </div>
    );
  }

  return (
    <div style={{ padding: "24px", minHeight: "100vh" }}>
      <Row gutter={[16, 16]} justify="center">
        {folderDetails && (
          <>
            <Col xs={24} lg={14}>
              <Card
                title="Détails du dossier"
                bordered={false}
                style={{ marginBottom: 24, border: `1px solid ${MAIN_COLOR}` }}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={8}>
                    <p style={{ fontSize: "18px" }}>
                      <HomeOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Type de bien :</StyledBoldText>{" "}
                      <StyledText>{folderDetails.propertyType}</StyledText>
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      <CheckCircleOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Condition :</StyledBoldText>{" "}
                      <StyledText>{folderDetails.condition}</StyledText>
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      <PercentageOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Frais de notaire :</StyledBoldText>{" "}
                      <StyledText>
                        {folderDetails.notaryFeesPercentage}%
                      </StyledText>
                    </p>
                  </Col>
                  <Col xs={24} md={8}>
                    <p style={{ fontSize: "18px" }}>
                      <EuroCircleOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Prix du bien :</StyledBoldText>{" "}
                      <StyledText>
                        {formatNumber(folderDetails.propertyPrice)}€
                      </StyledText>
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      <BuildOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Budget des travaux :</StyledBoldText>{" "}
                      <StyledText>
                        {formatNumber(folderDetails.renovationBudget)}€
                      </StyledText>
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      <DollarCircleOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Apport souhaité :</StyledBoldText>{" "}
                      <StyledText>
                        {formatNumber(folderDetails.desiredContribution)}€
                      </StyledText>
                    </p>
                  </Col>
                  <Col xs={24} md={8}>
                    <p style={{ fontSize: "18px" }}>
                      <EnvironmentOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Adresse :</StyledBoldText>{" "}
                      <StyledText>{folderDetails.address}</StyledText>
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      <ClockCircleOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Durée du prêt :</StyledBoldText>{" "}
                      <StyledText>{folderDetails.loanDuration} ans</StyledText>
                    </p>
                    <p style={{ fontSize: "18px" }}>
                      <InfoCircleOutlined
                        style={{ color: MAIN_COLOR, marginRight: 8 }}
                      />
                      <StyledBoldText>Statut :</StyledBoldText>{" "}
                      <StyledText>{folderDetails.status}</StyledText>
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={6}>
              <Card
                title="Informations de l'utilisateur"
                bordered={false}
                style={{ border: `1px solid ${MAIN_COLOR}` }}
              >
                <p style={{ fontSize: "18px" }}>
                  <UserOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <StyledBoldText>Prénom :</StyledBoldText>{" "}
                  <StyledText>{userDetails?.firstname}</StyledText>
                </p>
                <p style={{ fontSize: "18px" }}>
                  <UserOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <StyledBoldText>Nom :</StyledBoldText>{" "}
                  <StyledText>{userDetails?.lastname}</StyledText>
                </p>
                <p style={{ fontSize: "18px" }}>
                  <MailOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <StyledBoldText>Email :</StyledBoldText>{" "}
                  <StyledText>{userDetails?.email}</StyledText>
                </p>
              </Card>
            </Col>
            <Col xs={24}>
              <Card
                title="Documents"
                bordered={false}
                style={{ marginBottom: 24 }}
              >
                <AccordionBackoffice
                  files={folderDetails.files}
                  folderId={folderDetails.id}
                />
              </Card>
            </Col>
            {folderDetails.status === REFolderStatus.Analysing && (
              <Col xs={24}>
                <ReviewBackoffice />
              </Col>
            )}
            {folderDetails.status === REFolderStatus.Auctioned && (
              <Col xs={24}>
                <BankMail />
              </Col>
            )}
          </>
        )}
      </Row>
      <Row justify="end" style={{ marginTop: 24 }}>
        <Button type="primary" onClick={handleBackClick}>
          Retour
        </Button>
      </Row>
    </div>
  );
};

export default FolderDetailsBackoffice;
