import React, { useEffect, useState } from "react";
import { List, Typography, Card, Row, Col, Badge, Space } from "antd";
import {
  HomeOutlined,
  DollarOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  ToolOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAllFolders } from "../services/RealEstateFolder.service";
import { FolderDetails } from "../types/REFolder.interface";
import { MAIN_COLOR } from "../utils";
import { checkApiError } from "../apiCheckError";

const { Paragraph, Text } = Typography;

const StyledCard = styled(Card)`
  cursor: pointer;
  border: 1px solid ${MAIN_COLOR};
  .ant-card-body {
    padding: 12px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 16px;
  font-size: 20px;
`;

const StyledText = styled(Text)`
  font-size: 20px;
`;

const IconStyle = styled.span`
  color: ${MAIN_COLOR};
  margin-right: 8px;
`;

const Container = styled.div`
  padding: 20px;
  max-width: 900px;
  min-width: 50vw;
  min-height: 100vh;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 10px;
    min-width: 90vw;
  }
`;

const MesDossiers: React.FC = () => {
  const [folders, setFolders] = useState<FolderDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFolders = async () => {
      const result = await getAllFolders();
      checkApiError(result.respStatus);
      setFolders(result.respData);
      setLoading(false);
    };

    fetchFolders();
  }, []);

  const handleCardClick = (id: string) => {
    navigate(`/mes-dossiers/${id}`);
  };

  const formatAddress = (address: string) => {
    if (!address) return null;
    const parts = address.split(",");
    return parts
      .filter((part) => part.trim() !== "")
      .map((part) => <div key={part}>{part.trim()}</div>);
  };

  const formatNumber = (num: number) => {
    return num.toLocaleString("fr-FR");
  };

  return (
    <Container>
      <List
        loading={loading}
        grid={{ gutter: 16, column: 1 }}
        dataSource={folders}
        renderItem={(folder) => (
          <List.Item>
            <StyledCard
              title={<StyledText strong>{folder.propertyType}</StyledText>}
              onClick={() => handleCardClick(folder.id)}
              hoverable
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <StyledParagraph>
                    <IconStyle>
                      <HomeOutlined />
                    </IconStyle>
                    <StyledText strong>Type: </StyledText> {folder.propertyType}
                  </StyledParagraph>
                  <StyledParagraph>
                    <IconStyle>
                      <DollarOutlined />
                    </IconStyle>
                    <StyledText strong>Prix: </StyledText>{" "}
                    {formatNumber(folder.propertyPrice)} €
                  </StyledParagraph>
                  <StyledParagraph>
                    <IconStyle>
                      <CalendarOutlined />
                    </IconStyle>
                    <StyledText strong>Durée du prêt: </StyledText>{" "}
                    {folder.loanDuration} ans
                  </StyledParagraph>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <StyledParagraph>
                    <IconStyle>
                      <ToolOutlined />
                    </IconStyle>
                    <StyledText strong>Budget de rénovation: </StyledText>{" "}
                    {formatNumber(folder.renovationBudget)} €
                  </StyledParagraph>
                  <StyledParagraph>
                    <IconStyle>
                      <DollarOutlined />
                    </IconStyle>
                    <StyledText strong>Contribution: </StyledText>{" "}
                    {formatNumber(folder.desiredContribution)} €
                  </StyledParagraph>
                  <StyledParagraph>
                    <IconStyle>
                      <PercentageOutlined />
                    </IconStyle>
                    <StyledText strong>Frais de notaire: </StyledText>{" "}
                    {folder.notaryFeesPercentage}%
                  </StyledParagraph>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <StyledParagraph>
                    <IconStyle>
                      <EnvironmentOutlined />
                    </IconStyle>
                    <StyledText strong>Adresse: </StyledText>{" "}
                    {formatAddress(folder.address)}
                  </StyledParagraph>
                  <Space direction="horizontal" align="center">
                    <Badge color="green" />
                    <StyledText>{folder.status}</StyledText>
                  </Space>
                </Col>
              </Row>
            </StyledCard>
          </List.Item>
        )}
      />
    </Container>
  );
};

export default MesDossiers;
