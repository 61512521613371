import React, { useState, useEffect, useCallback } from "react";
import { Card, InputNumber, Button, message, Row, Col, Typography } from "antd";
import { useParams } from "react-router-dom";
import CustomDraggerBackoffice from "./CustomDraggerBackoffice";
import {
  FILENAME_FINANCING_PLAN,
  FILENAME_SUMMARY_NOTE,
  FileReviewBackoffice,
  REFolderStatus,
} from "../../../types/REFolder.interface";
import {
  updateAdminFolderById,
  findAdminFolderById,
  emailAdminValidFolder,
} from "../../../services/RealEstateFolder.service";
import { checkApiError } from "../../../apiCheckError";

const { Text } = Typography;

const ReviewBackoffice: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [note, setNote] = useState<number | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const checkUploadedFiles = useCallback(async () => {
    const folderResponse = await findAdminFolderById(id!);
    checkApiError(folderResponse.respStatus);
    const folder = folderResponse.respData;
    if (folder.scoring) setNote(folder.scoring);
  }, [id]);

  useEffect(() => {
    checkUploadedFiles();
  }, [checkUploadedFiles]);

  const handleNoteChange = (value: number | null) => {
    setNote(value);
  };

  const handleSubmit = async () => {
    const folderResponse = await findAdminFolderById(id!);
    checkApiError(folderResponse.respStatus);
    const folder = folderResponse.respData;
    const isSummaryNoteUploaded =
      folder.summaryNoteFile !== "" && folder.summaryNoteFile !== undefined;
    const isFinancingPlanUploaded =
      folder.financingPlanFile !== "" && folder.financingPlanFile !== undefined;

    if (note === null) {
      message.error("Veuillez entrer une note avant de valider.");
      return;
    }
    if (!isSummaryNoteUploaded || !isFinancingPlanUploaded) {
      message.error(
        "Veuillez télécharger la note de synthèse et le plan de financement avant de valider.",
      );
      return;
    }

    checkApiError(
      (
        await updateAdminFolderById(id!, {
          scoring: note,
          status: REFolderStatus.DocumentVerification,
        })
      ).respStatus,
    );
    checkApiError((await emailAdminValidFolder(id!)).respStatus);
    setIsSubmitted(true);
    message.success("Passage du dossier en vérification des documents");
  };

  return (
    <>
      {!isSubmitted && (
        <Card title="Faire un retour" bordered={false}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <CustomDraggerBackoffice
                folderId={id!}
                title={FileReviewBackoffice.Note}
                description="Veuillez télécharger la note de synthèse du projet."
                fileName={FILENAME_SUMMARY_NOTE}
              />
            </Col>
            <Col xs={24} md={12}>
              <CustomDraggerBackoffice
                folderId={id!}
                title={FileReviewBackoffice.FinancingPlan}
                description="Veuillez télécharger le plan de financement du projet."
                fileName={FILENAME_FINANCING_PLAN}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center" style={{ marginTop: 16 }}>
            <Col xs={24} md={8}>
              <Text>Veuillez entrer une note pour ce dossier</Text>
            </Col>
            <Col xs={24} md={4}>
              <InputNumber
                min={0}
                max={10}
                value={note}
                onChange={handleNoteChange}
                placeholder="Note sur 10"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} md={4}>
              <Button
                type="primary"
                onClick={handleSubmit}
                style={{ marginLeft: 8, width: "100%" }}
              >
                Valider
              </Button>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default ReviewBackoffice;
