import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, Typography, Spin, message, Row, Col } from "antd";
import styled from "styled-components";
import {
  EuroCircleOutlined,
  PercentageOutlined,
  CalendarOutlined,
  BankOutlined,
  AimOutlined,
  FileOutlined,
  HomeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { BidProposition, FolderDetails } from "../types/REFolder.interface";
import {
  getFolderById,
  validateBid,
} from "../services/RealEstateFolder.service";
import { MAIN_COLOR } from "../utils";
import { checkApiError } from "../apiCheckError";

const { Title, Text } = Typography;

const Container = styled.div`
  padding: 20px;
  width: 100%;
  text-align: center;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  text-align: left;
  font-size: 18px;
  border: 1px solid ${MAIN_COLOR};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const StyledText = styled(Text)`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const formatNumber = (value: number | string | undefined) => {
  if (!value) return "";
  return new Intl.NumberFormat("fr-FR").format(Number(value));
};

const Bids: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [folder, setFolder] = useState<FolderDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFolder = async () => {
      const response = await getFolderById(id!);
      checkApiError(response.respStatus);
      const fetchedFolder = response.respData;
      setFolder(fetchedFolder);
      setLoading(false);
    };

    fetchFolder();
  }, [id]);

  const handleButtonClick = async (
    bidId: string | undefined,
    propositionId: string | undefined,
  ) => {
    if (bidId && id && propositionId) {
      checkApiError((await validateBid(id, bidId, propositionId)).respStatus);
      message.success("Enchère choisie, félicitations !");
      navigate("/mes-dossiers/" + id);
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Container>
      <Title level={2}>Propositions d'offres</Title>
      <Row gutter={[16, 16]}>
        {folder?.bidInfos &&
          folder.bidInfos
            .filter((bid) => bid.active)
            .map((bid, index) =>
              bid.bidPropositions.map(
                (proposition: BidProposition, propIndex: number) => (
                  <Col xs={24} sm={24} md={12} lg={12} key={proposition.id}>
                    <StyledCard title={`Proposition ${propIndex + 1}`}>
                      <Row>
                        <Col xs={24} sm={12}>
                          <p>
                            <EuroCircleOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>Montant : </StyledText>
                            <StyledText>
                              {" "}
                              {formatNumber(proposition.amount)} €
                            </StyledText>
                          </p>
                          <p>
                            <PercentageOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>Taux : </StyledText>
                            <StyledText> {proposition.rate} %</StyledText>
                          </p>
                          <p>
                            <CalendarOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>Durée en années : </StyledText>
                            <StyledText>
                              {" "}
                              {proposition.durationInYear}
                            </StyledText>
                          </p>
                          <p>
                            <BankOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>Apport : </StyledText>
                            <StyledText>
                              {" "}
                              {formatNumber(proposition.contribution)} €
                            </StyledText>
                          </p>
                        </Col>
                        <Col xs={24} sm={12}>
                          <p>
                            <AimOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>
                              Domiciliation des revenus :
                            </StyledText>
                            <StyledText>
                              {" "}
                              {proposition.domiciliationOfIncomes
                                ? "Oui"
                                : "Non"}
                            </StyledText>
                          </p>
                          <p>
                            <FileOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>
                              Domiciliation des épargnes :
                            </StyledText>
                            <StyledText>
                              {" "}
                              {proposition.domiciliationOfSavings
                                ? "Oui"
                                : "Non"}
                            </StyledText>
                          </p>
                          <p>
                            <HomeOutlined
                              style={{ color: MAIN_COLOR, marginRight: 8 }}
                            />
                            <StyledText strong>
                              Assurance habitation :
                            </StyledText>
                            <StyledText>
                              {" "}
                              {proposition.homeInsurance ? "Oui" : "Non"}
                            </StyledText>
                          </p>
                          {proposition.otherConditions && (
                            <p>
                              <FileTextOutlined
                                style={{ color: MAIN_COLOR, marginRight: 8 }}
                              />
                              <StyledText strong>
                                Autres conditions :
                              </StyledText>
                              <StyledText>
                                {" "}
                                {proposition.otherConditions}
                              </StyledText>
                            </p>
                          )}
                        </Col>
                      </Row>
                      <ButtonContainer>
                        <Button
                          onClick={() =>
                            handleButtonClick(bid.id, proposition.id)
                          }
                          type="primary"
                        >
                          Faire affaire !
                        </Button>
                      </ButtonContainer>
                    </StyledCard>
                  </Col>
                ),
              ),
            )}
      </Row>
    </Container>
  );
};

export default Bids;
