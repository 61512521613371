import { Card, Typography } from "antd";
import { Form, Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  findFolderById,
  updateFolderById,
} from "../../services/RealEstateFolder.service";
import LeftCol from "./LeftCol";
import RightCol from "./RightCol";
import {
  Condition,
  FolderDetails,
  REFolderStatus,
} from "../../types/REFolder.interface";
import { MAIN_COLOR } from "../../utils";
import { checkApiError } from "../../apiCheckError";

const { Title } = Typography;

const StyledCard = styled(Card)`
  width: 80%;
  margin: 0 auto;
  border: 1px solid ${MAIN_COLOR};

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const CenteredTitle = styled(Title)`
  text-align: center;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  justify-content: flex-end;
  right: 20px;

  @media (max-width: 768px) {
    position: static;
    margin-top: 20px;
    text-align: center;
  }
`;

const SimulationText = styled.div`
  font-size: 18px;
  padding: 8px;
  border-radius: 8px;

  .title {
    font-weight: bold;
    margin-bottom: 16px;
    font-size: 24px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .detail1 {
    font-size: 18px;
  }

  .detail2 {
    font-size: 16px;
  }

  .price1 {
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    flex: 1;
  }

  .price2 {
    font-size: 16px;
    text-align: right;
    flex: 1;
  }
`;

const VerticalDivider = styled.div`
  border-left: 2px solid #d9d9d9;
  height: 100%;
  margin: 0 16px;
`;

const Modification: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const navigate = useNavigate();

  const [loanAmount, setLoanAmount] = useState(330000);
  const [monthlyPayment, setMonthlyPayment] = useState(2493);
  const [monthlyInsurance, setMonthlyInsurance] = useState(94);
  const [totalCreditCost, setTotalCreditCost] = useState(0);
  const [totalInsuranceCost, setTotalInsuranceCost] = useState(0);

  const propertyPrice = parseFloat(watch("propertyPrice") || "0");
  const renovationBudget = parseFloat(watch("renovationBudget") || "0");
  const loanDuration = parseFloat(watch("loanDuration") || "3") * 12;
  const desiredContribution = parseFloat(watch("desiredContribution") || "0");
  const interestRate = parseFloat(watch("interestRate") || "4") / 100;
  const insuranceRate = parseFloat(watch("insuranceRate") || "0.3") / 100;

  useEffect(() => {
    const fetchData = async () => {
      const response = await findFolderById(id!);
      checkApiError(response.respStatus);
      const folder = response.respData;
      (Object.keys(folder) as (keyof FolderDetails)[]).forEach((key) => {
        setValue(key, folder[key]);
      });

      const addressComponents = folder.address.split(", ");
      const numAndStreet = addressComponents[0].split(" ");
      const num = numAndStreet[0];
      const street = numAndStreet.slice(1).join(" ");
      const zipAndCity = addressComponents[1].split(" ");
      const zip_code = zipAndCity[0];
      const city = zipAndCity.slice(1).join(" ");
      const country = addressComponents[2];

      setValue("address.num", num);
      setValue("address.street", street);
      setValue("address.zip_code", zip_code);
      setValue("address.city", city);
      setValue("address.country", country);
    };

    fetchData();
  }, [id, setValue]);

  useEffect(() => {
    const totalCost = propertyPrice + renovationBudget;
    const loanAmount = totalCost - desiredContribution;
    const monthlyInterestRate = interestRate / 12;
    const numberOfPayments = loanDuration;

    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    const annualInsurance = loanAmount * insuranceRate;
    const monthlyInsurance = annualInsurance / 12;

    setLoanAmount(Math.ceil(loanAmount));
    setMonthlyPayment(Math.ceil(monthlyPayment));
    setMonthlyInsurance(Math.ceil(monthlyInsurance));
    const totalInsuranceCost = Math.ceil(monthlyInsurance * numberOfPayments);
    const totalCreditCost = Math.ceil(
      monthlyPayment * numberOfPayments - loanAmount + totalInsuranceCost,
    );

    setTotalCreditCost(totalCreditCost);
    setTotalInsuranceCost(totalInsuranceCost);
  }, [
    propertyPrice,
    renovationBudget,
    loanDuration,
    desiredContribution,
    interestRate,
    insuranceRate,
  ]);

  const handleButtonClick = async () => {
    const values = methods.getValues();
    const data: Partial<FolderDetails> = {
      propertyType: values.propertyType,
      condition: Condition.Old,
      propertyPrice: parseFloat(values.propertyPrice),
      renovationBudget: parseFloat(values.renovationBudget),
      address: `${values.address.num} ${values.address.street}, ${values.address.zip_code} ${values.address.city}, ${values.address.country}`,
      notaryFeesPercentage: 8,
      loanDuration: parseInt(values.loanDuration),
      desiredContribution: parseFloat(values.desiredContribution),
      status: REFolderStatus.Completion,
    };

    checkApiError((await updateFolderById(id!, data)).respStatus);
    navigate(0);
  };

  return (
    <FormProvider {...methods}>
      <StyledCard
        title={<CenteredTitle level={3}>Modifier mon dossier</CenteredTitle>}
        bordered={false}
      >
        <Form layout="vertical">
          <Row gutter={[8, 8]}>
            <Col xs={24} md={11}>
              <LeftCol control={control} />
            </Col>
            <VerticalDivider />
            <Col xs={24} md={11}>
              <RightCol control={control} />
              <SimulationText>
                <div className="title">Votre simulation :</div>
                <div className="row">
                  <div className="detail1">Montant de votre prêt :</div>
                  <div className="price1">{loanAmount.toLocaleString()}€</div>
                </div>
                <div className="row">
                  <div className="detail1">Votre mensualité :</div>
                  <div className="price1">
                    {(monthlyPayment + monthlyInsurance).toLocaleString()}€/mois
                  </div>
                </div>
                <div className="row">
                  <div className="detail2">dont assurance :</div>
                  <div className="price2">
                    {monthlyInsurance.toLocaleString()}€/mois
                  </div>
                </div>
                <div className="row">
                  <div className="detail1">Coût total du crédit :</div>
                  <div className="price1">
                    {totalCreditCost.toLocaleString()}€
                  </div>
                </div>
                <div className="row">
                  <div className="detail2">dont assurance :</div>
                  <div className="price2">
                    {totalInsuranceCost.toLocaleString()}€
                  </div>
                </div>
              </SimulationText>
            </Col>
          </Row>
        </Form>
        <ButtonWrapper>
          <Button type="primary" onClick={handleButtonClick}>
            Modifier mon dossier
          </Button>
        </ButtonWrapper>
      </StyledCard>
    </FormProvider>
  );
};

export default Modification;
