import React, { useState } from "react";
import { Card, Row, Col, Button, message } from "antd";
import styled from "styled-components";
import {
  EuroOutlined,
  PercentageOutlined,
  CalendarOutlined,
  BankOutlined,
  HomeOutlined,
  FileTextOutlined,
  AimOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { UserType } from "../../../types";
import {
  FolderDetails,
  BidProposition,
  REFolderStatus,
} from "../../../types/REFolder.interface";
import { BACKGROUND_COLOR, MAIN_COLOR } from "../../../utils";
import { updateBankFolderById } from "../../../services/RealEstateFolder.service";
import FolderInformations from "./FolderInformations";
import { checkApiError } from "../../../apiCheckError";

const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const InfoText = styled.p`
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const OfferCard = styled(Card)`
  margin-top: 16px;
  font-size: 18px;
  background-color: ${BACKGROUND_COLOR};
  border: 1px solid ${MAIN_COLOR};

  .ant-card-head-title {
    font-size: 20px;
    color: white;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .offer-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .anticon {
      margin-right: 8px;
      font-size: 20px;
      color: ${MAIN_COLOR};

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
`;

const BidWon: React.FC<{
  folder: FolderDetails | null;
  user: UserType | null;
  bidPropositions: BidProposition[];
}> = ({ folder, user, bidPropositions }) => {
  const [localFolder, setLocalFolder] = useState(folder);

  const handleStatusChange = async (newStatus: REFolderStatus) => {
    if (localFolder?.id) {
      try {
        const updatedFolderResponse = await updateBankFolderById(
          localFolder.id,
          {
            status: newStatus,
          },
        );
        checkApiError(updatedFolderResponse.respStatus);
        const updatedFolder = updatedFolderResponse.respData;
        setLocalFolder(updatedFolder);
        message.success("Le statut du dossier a été mis à jour avec succès !");
      } catch (error) {
        message.error("Erreur lors de la mise à jour du statut du dossier.");
      }
    }
  };

  return (
    <Container>
      <Row gutter={[14, 14]}>
        <Col xs={24} md={12}>
          <FolderInformations folder={localFolder} user={user} />
        </Col>
        <Col xs={24} md={12}>
          {bidPropositions.map((bid, index) => (
            <OfferCard title={`Enchère ${index + 1}`} key={index}>
              <Row>
                <Col xs={24} sm={12}>
                  <div className="offer-info">
                    <EuroOutlined />
                    <InfoText>
                      <BoldText>Montant : </BoldText>
                      {bid.amount} €
                    </InfoText>
                  </div>
                  <div className="offer-info">
                    <PercentageOutlined />
                    <InfoText>
                      <BoldText>Taux : </BoldText>
                      {bid.rate} %
                    </InfoText>
                  </div>
                  <div className="offer-info">
                    <CalendarOutlined />
                    <InfoText>
                      <BoldText>Durée : </BoldText>
                      {bid.durationInYear} ans
                    </InfoText>
                  </div>
                  <div className="offer-info">
                    <BankOutlined />
                    <InfoText>
                      <BoldText>Apport : </BoldText>
                      {bid.contribution} €
                    </InfoText>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="offer-info">
                    <AimOutlined />
                    <InfoText>
                      <BoldText>Domiciliation des revenus : </BoldText>
                      {bid.domiciliationOfIncomes ? "Oui" : "Non"}
                    </InfoText>
                  </div>
                  <div className="offer-info">
                    <FileDoneOutlined />
                    <InfoText>
                      <BoldText>Domiciliation de l'épargne : </BoldText>
                      {bid.domiciliationOfSavings ? "Oui" : "Non"}
                    </InfoText>
                  </div>
                  <div className="offer-info">
                    <HomeOutlined />
                    <InfoText>
                      <BoldText>Assurance habitation : </BoldText>
                      {bid.homeInsurance ? "Oui" : "Non"}
                    </InfoText>
                  </div>
                  {bid.otherConditions && (
                    <div className="offer-info">
                      <FileTextOutlined />
                      <InfoText>
                        <BoldText>Autres conditions : </BoldText>
                        {bid.otherConditions}
                      </InfoText>
                    </div>
                  )}
                </Col>
              </Row>
              {localFolder &&
                localFolder.status ===
                  REFolderStatus.WaitingForBankAppointment && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleStatusChange(
                        REFolderStatus.WaitingForOfferSignature,
                      )
                    }
                  >
                    RDV banque pris
                  </Button>
                )}
              {localFolder &&
                localFolder.status ===
                  REFolderStatus.WaitingForOfferSignature && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleStatusChange(
                        REFolderStatus.WaitingForNotaryAppointment,
                      )
                    }
                  >
                    Offre signée
                  </Button>
                )}
              {localFolder &&
                localFolder.status ===
                  REFolderStatus.WaitingForNotaryAppointment && (
                  <Button
                    type="primary"
                    onClick={() => handleStatusChange(REFolderStatus.Done)}
                  >
                    RDV notaire pris
                  </Button>
                )}
            </OfferCard>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default BidWon;
