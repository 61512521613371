export interface UserType {
  firstname: string;
  lastname: string;
  email: string;
  activated?: boolean;
  email_validated?: boolean;
  role?: string;
  connections?: number;
  created_at?: string;
  updated_at?: string;
  tmp_email?: string;
  admin_role?: AdminRoles;
  currentBank?: string;
  id: string;
}

export interface UserBankType {
  firstname: string;
  email: string;
  activated: boolean;
  email_validated: boolean;
  role: UserRoles;
  connections: number;
  created_at: string;
  updated_at: string;
  validation_code?: string;
  currentBank?: PartnerBanks;
  id: string;
}

export interface SignUpType {
  firstname: string;
  lastname: string;
  email: string;
  currentBank?: string;
  password: string;
  confirm_password: string | undefined;
}

export interface ToLoginType {
  email: string;
  password: string;
}
export interface ToValidForgetPassword {
  email: string;
}

export interface ToValidResetPassword {
  password: string;
  new_password: string;
}

export interface ValidEmailType {
  code: string;
}

export interface LoginType {
  token: string;
  user: UserType;
}

export enum AdminRoles {
  SuperAdmin = "SuperAdmin",
}

export enum UserRoles {
  User = "Free",
  Bank = "BankAccount",
}

export enum PartnerBanks {
  BNP = "BNP",
  LCL = "LCL",
  CIC = "CIC",
  Boursorama = "Boursorama",
  CreditAgricole = "Credit Agricole",
  Other = "Autre",
}
