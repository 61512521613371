import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, theme, message } from "antd";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { confirmEmail } from "../services/users.service";
import { useUser } from "../context/UserContext";
import { submitData } from "../services/RealEstateFolder.service";
import { checkApiError } from "../apiCheckError";

type ValidationFormType = {
  code: string;
};

export default function EmailValidation() {
  const { token } = theme.useToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setUser } = useUser();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ValidationFormType>();

  const onSubmit = async (data: ValidationFormType) => {
    setIsSubmitting(true);
    const response = await confirmEmail(id!, data.code);
    checkApiError(response.respStatus);
    setIsSubmitting(false);
    setUser(response.respData.user);
    message.success("Félicitations, votre email a été validé");

    const savedFormData = localStorage.getItem("savedFormData");
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      const result = await submitData(formData);
      checkApiError(result.respStatus);
      localStorage.removeItem("savedFormData");
      navigate(`/mes-dossiers/${result.respData.id}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: token.colorBgContainer,
          padding: "24px",
          borderRadius: "8px",
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <Form layout="vertical">
          <Form.Item
            label="Code de validation"
            validateStatus={errors.code ? "error" : ""}
            help={errors.code ? errors.code.message : ""}
            style={{ marginBottom: "24px" }}
          >
            <Controller
              name="code"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  size="large"
                  prefix={<LockOutlined />}
                  placeholder="Code de validation"
                />
              )}
              rules={{ required: "Le code de validation est obligatoire" }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={handleSubmit(onSubmit)}
              block
              loading={isSubmitting}
              style={{ marginTop: "24px" }}
            >
              Valider
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
