import React, { useState, useEffect } from "react";
import { Upload, Card, message } from "antd";
import { UploadFile, RcFile } from "antd/es/upload/interface";
import { InboxOutlined } from "@ant-design/icons";
import { FileReviewBackoffice } from "../../../types/REFolder.interface";
import {
  uploadAdminFile,
  deleteAdminFileById,
  findAdminFolderById,
} from "../../../services/RealEstateFolder.service";
import { checkApiError } from "../../../apiCheckError";

const { Dragger } = Upload;

interface CustomDraggerBackofficeProps {
  folderId: string;
  title: string;
  description: string;
  fileName: string;
}

const CustomDraggerBackoffice: React.FC<CustomDraggerBackofficeProps> = ({
  folderId,
  title,
  description,
  fileName,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

  useEffect(() => {
    const fetchFile = async () => {
      const folderResponse = await findAdminFolderById(folderId);
      checkApiError(folderResponse.respStatus);
      const folder = folderResponse.respData;
      const file =
        title === FileReviewBackoffice.FinancingPlan
          ? folder.financingPlanFile
          : folder.summaryNoteFile;
      if (file) {
        setFileList([
          {
            uid: "1",
            name: fileName,
            status: "done",
            url: file,
          },
        ]);
      }
    };
    fetchFile();
  }, [folderId, fileName, title]);

  const handleUpload = async ({ file }: { file: RcFile }) => {
    if (file.size > MAX_FILE_SIZE) {
      message.error(
        "Le fichier est trop volumineux. La taille maximale est de 5 Mo.",
      );
      return;
    }

    if (fileList.length > 0) {
      checkApiError((await deleteAdminFileById(folderId, fileName)).respStatus);
    }
    checkApiError(
      (await uploadAdminFile(folderId, file as File, fileName)).respStatus,
    );
    const newFile: UploadFile = {
      uid: file.uid,
      name: fileName,
      status: "done",
      url: URL.createObjectURL(file),
    };
    setFileList([newFile]);
  };

  const handleRemove = async () => {
    checkApiError((await deleteAdminFileById(folderId, fileName)).respStatus);
    setFileList([]);
  };

  return (
    <Card bordered={false} style={{ marginBottom: 16 }}>
      <Dragger
        multiple={false}
        customRequest={({ file, onSuccess }) => {
          handleUpload({ file: file as RcFile }).then(() => {
            if (onSuccess) {
              onSuccess("ok");
            }
          });
        }}
        fileList={fileList}
        onRemove={handleRemove}
        accept="image/*,video/*,application/pdf"
        style={{ marginTop: 16 }}
        beforeUpload={(file) => {
          if (file.size > MAX_FILE_SIZE) {
            message.error(
              "Le fichier est trop volumineux. La taille maximale est de 5 Mo.",
            );
            return Upload.LIST_IGNORE;
          }
          return true;
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{title}</p>
        <p className="ant-upload-hint">{description}</p>
      </Dragger>
    </Card>
  );
};

export default CustomDraggerBackoffice;
