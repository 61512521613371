import { Layout, Menu, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/reeady.svg";
import { useUser } from "../../context/UserContext";

const { useToken } = theme;
const { Header } = Layout;

const Navbar: React.FC = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const { token } = useToken();
  const { user } = useUser();

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedKey("1");
    } else if (location.pathname === "/mes-dossiers") {
      setSelectedKey("2");
    } else if (location.pathname.match(/^\/mes-dossiers\/[a-zA-Z0-9-]+$/)) {
      setSelectedKey("2");
    } else if (location.pathname === "/contact") {
      setSelectedKey("3");
    } else if (location.pathname === "/authentification") {
      setSelectedKey("4");
    } else if (location.pathname === "/profil") {
      setSelectedKey("5");
    }
  }, [location.pathname]);

  const handleLogoClick = () => {
    setSelectedKey("1");
  };

  const items = [
    { key: "1", label: "Menu principal", link: "/" },
    user && { key: "2", label: "Mes dossiers", link: "/mes-dossiers" },
    { key: "3", label: "Contact", link: "/contact" },
    !user && { key: "4", label: "Authentification", link: "/authentification" },
    user && { key: "5", label: `${user.firstname}`, link: "/profil" },
  ].filter((item): item is { key: string; label: string; link: string } =>
    Boolean(item),
  );

  const menuItems = items.map((item) => ({
    key: item.key,
    label: <Link to={item.link}>{item.label}</Link>,
  }));

  return (
    <div style={{ paddingBottom: "15px" }}>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: token.colorBgBase,
          borderBottom: `1px solid ${token.colorPrimary}`,
        }}
      >
        <div>
          <Link to="/" onClick={handleLogoClick}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: "40px", display: "block" }}
            />
          </Link>
        </div>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{
            flex: 1,
            justifyContent: "flex-end",
            backgroundColor: token.colorBgBase,
            borderBottom: "0px transparent",
          }}
          items={menuItems}
        />
      </Header>
    </div>
  );
};

export default Navbar;
