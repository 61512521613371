import {
  API_REA_FOLDERS,
  API_ADMIN_REA_FOLDERS,
  API_URL,
  API_INVALID_FILES,
  API_REA_FOLDER_OK,
  API_REA_FOLDER_BANKS,
  API_BANK_REA_FOLDERS,
  API_BANK_sendProposition,
  API_validate_bid,
} from "./ApiPaths";
import {
  BidProposition,
  FileData,
  FolderDetails,
} from "../types/REFolder.interface";

export const getFolderById = async (
  folderId: string,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(`${API_URL}${API_REA_FOLDERS}/${folderId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const getAllFolders = async (): Promise<{
  respData: FolderDetails[];
  respStatus: number;
}> => {
  const response = await fetch(`${API_URL}${API_REA_FOLDERS}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const getAdminAllFolders = async (): Promise<{
  respData: FolderDetails[];
  respStatus: number;
}> => {
  const response = await fetch(`${API_URL}${API_ADMIN_REA_FOLDERS}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const submitData = async (
  levelData: Partial<FolderDetails>,
): Promise<{ respData: Partial<FolderDetails>; respStatus: number }> => {
  const response = await fetch(`${API_URL}${API_REA_FOLDERS}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(levelData),
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const findFolderById = async (
  folderId: string,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(`${API_URL}${API_REA_FOLDERS}/${folderId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const findAdminFolderById = async (
  folderId: string,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const findBankFolderById = async (
  folderId: string,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_BANK_REA_FOLDERS}/${folderId}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const updateFolderById = async (
  folderId: string,
  updateData: Partial<FolderDetails>,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(`${API_URL}${API_REA_FOLDERS}/${folderId}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateData),
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const updateAdminFolderById = async (
  folderId: string,
  updateData: Partial<FolderDetails>,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}`,
    {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateData),
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const uploadFile = async (
  folderId: string,
  file: File,
  name: string,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(
    `${API_URL}${API_REA_FOLDERS}/${folderId}/files?name=${name}`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const uploadAdminFile = async (
  folderId: string,
  file: File,
  name: string,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}/files?name=${name}`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const updateAdminFile = async (
  folderId: string,
  fileId: string,
  modification: object,
): Promise<{ respData: FileData; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}/files/${fileId}`,
    {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(modification),
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const deleteFileById = async (
  folderId: string,
  fileId: string,
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_REA_FOLDERS}/${folderId}/files/${fileId}`,
    {
      method: "DELETE",
      credentials: "include",
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const deleteAdminFileById = async (
  folderId: string,
  fileId: string,
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}/files/${fileId}`,
    {
      method: "DELETE",
      credentials: "include",
    },
  );
  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const emailAdminInvalidFiles = async (
  folderId: string,
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}${API_INVALID_FILES}`,
    {
      method: "POST",
      credentials: "include",
    },
  );
  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const emailAdminValidFolder = async (
  folderId: string,
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}${API_REA_FOLDER_OK}`,
    {
      method: "POST",
      credentials: "include",
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const sendFolderToBanks = async (
  folderId: string,
  emails: string[],
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_ADMIN_REA_FOLDERS}/${folderId}${API_REA_FOLDER_BANKS}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails }),
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const getBankFolders = async (): Promise<{
  respData: FolderDetails[];
  respStatus: number;
}> => {
  const response = await fetch(`${API_URL}${API_BANK_REA_FOLDERS}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const sendProposition = async (
  folderId: string,
  proposition: BidProposition,
): Promise<{ respData: void; respStatus: number }> => {
  const payload: BidProposition = { ...proposition };

  const response = await fetch(
    `${API_URL}${API_BANK_REA_FOLDERS}/${folderId}${API_BANK_sendProposition}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;

  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const updateBankFolderById = async (
  folderId: string,
  updateData: Partial<FolderDetails>,
): Promise<{ respData: FolderDetails; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_BANK_REA_FOLDERS}/${folderId}`,
    {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateData),
    },
  );

  const responseData = await response.json();
  const responseStatus = response.status;
  return {
    respData: responseData,
    respStatus: responseStatus,
  };
};

export const validateBid = async (
  folderId: string,
  bidId: string,
  propositionId: string,
): Promise<{ respData: void; respStatus: number }> => {
  const response = await fetch(
    `${API_URL}${API_REA_FOLDERS}/${folderId}${API_validate_bid.replace(":bidId", bidId)}${propositionId}`,
    {
      method: "POST",
      credentials: "include",
    },
  );

  const responsedata = await response.json();
  const responseStatus = response.status;

  return {
    respData: responsedata,
    respStatus: responseStatus,
  };
};
