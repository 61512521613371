import React from "react";
import { Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
`;

const ProfileBank: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/banque");
  };

  return (
    <>
      <Container>
        <Title>Fonctionnalité à venir prochainement</Title>
        <Button type="primary" onClick={handleButtonClick}>
          Retour à l'accueil
        </Button>
      </Container>
    </>
  );
};

export default ProfileBank;
