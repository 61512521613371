import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, Typography } from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getAdminAllFolders } from "../../services/RealEstateFolder.service";
import { getUserById } from "../../services/users.service";
import { ICON_COLOR } from "../../utils";
import {
  Condition,
  CurrentResidentialSituation,
  FolderDetails,
  InvestmentType,
  PurchaseIn,
  REFolderStatus,
  Situation,
} from "../../types/REFolder.interface";
import { checkApiError } from "../../apiCheckError";
import {
  CAFFinancial,
  ChildProfile,
  CommonFinancial,
  CommonProfile,
  CommonProject,
  DivorcedProfile,
  EmployedFinancial,
  MarriedProfile,
  OngoingLoansFinancial,
  OwnerProfile,
  RLProject,
  RenterProfile,
  SCIProfile,
  desiredContributionFinancial,
  newProject,
  renovationBudgetProject,
} from "../../component/upload/FilesData";

const { Text } = Typography;

interface REFolderTableLine {
  key: string;
  status: string;
  missingFiles: number;
  firstName: string;
  lastName: string;
  projectSummary: string;
  actions: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
`;

const TableWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export const countTotalFiles = (folder: FolderDetails) => {
  if (!folder.numPeople) {
    return 0;
  }
  let count = 0;
  //Project
  count += CommonProject.length;
  if (folder.investmentType === InvestmentType.RentalProperty) {
    count += RLProject.length;
  } else if (folder.condition !== Condition.Old) {
    count += newProject.length;
  }
  if (folder.renovationBudget > 0) {
    count += renovationBudgetProject.length;
  }

  //Identity
  count += CommonProfile.length * folder.numPeople;
  if (folder.situation === Situation.Married) {
    count += MarriedProfile.length * folder.numPeople;
  } else if (folder.situation === Situation.Divorced) {
    count += DivorcedProfile.length * folder.numPeople;
  }
  if (
    folder.currentResidentialSituation === CurrentResidentialSituation.Owner
  ) {
    count += OwnerProfile.length * folder.numPeople;
  } else {
    count += RenterProfile.length * folder.numPeople;
  }
  if (folder.hasChildren) {
    count += ChildProfile.length * folder.numPeople;
  }
  if (folder.purchaseIn === PurchaseIn.InSCI) {
    count += SCIProfile.length * folder.numPeople;
  }

  //Financial
  count += CommonFinancial.length * folder.numPeople;
  if (folder.isEmployed) {
    count += EmployedFinancial.length * folder.numPeople;
  }
  if (folder.hasOngoingLoans) {
    count += OngoingLoansFinancial.length * folder.numPeople;
  }
  if (folder.hasCAF) {
    count += CAFFinancial.length * folder.numPeople;
  }
  if (folder.desiredContribution > 0) {
    count += desiredContributionFinancial.length * folder.numPeople;
  }

  return count;
};

const FoldersBackoffice: React.FC = () => {
  const [folders, setFolders] = useState<REFolderTableLine[]>([]);
  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined,
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const foldersResponse = await getAdminAllFolders();
      checkApiError(foldersResponse.respStatus);
      const foldersData = foldersResponse.respData;

      const foldersWithUserInfo = await Promise.all(
        foldersData.map(async (folder: FolderDetails) => {
          const userResponse = await getUserById(folder.userId);
          checkApiError(userResponse.respStatus);
          const user = userResponse.respData;
          const totalFilesCount = countTotalFiles(folder);
          const missingFiles = totalFilesCount - folder.files.length;
          const projectSummary = `${folder.propertyType} - ${folder.propertyPrice}€ - ${folder.loanDuration} ans`;
          return {
            key: folder.id,
            status: folder.status,
            missingFiles: missingFiles,
            firstName: user.firstname,
            lastName: user.lastname,
            projectSummary: projectSummary,
            actions: "Consulter",
          };
        }),
      );
      setFolders(foldersWithUserInfo);
    };

    fetchData();
  }, []);

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters?: () => void) => {
    if (clearFilters) {
      clearFilters();
    }
    setSearchText("");
    setSearchedColumn("");
    setStatusFilter(undefined);
  };

  const getColumnSearchProps = (
    dataIndex: keyof REFolderTableLine,
    placeholder: string,
  ): ColumnType<REFolderTableLine> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <StyledButton onClick={() => handleReset(clearFilters)} size="small">
            Réinitialiser
          </StyledButton>
          <StyledButton
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
          >
            Rechercher
          </StyledButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? ICON_COLOR : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false,
  });

  const columns: ColumnsType<REFolderTableLine> = [
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: REFolderStatus.AddingInformations,
          value: REFolderStatus.AddingInformations,
        },
        { text: REFolderStatus.Completion, value: REFolderStatus.Completion },
        {
          text: REFolderStatus.SignatureRequired,
          value: REFolderStatus.SignatureRequired,
        },
        { text: REFolderStatus.Analysing, value: REFolderStatus.Analysing },
        {
          text: REFolderStatus.DocumentVerification,
          value: REFolderStatus.DocumentVerification,
        },
        { text: REFolderStatus.Auction, value: REFolderStatus.Auction },
      ],
      onFilter: (value, record) => record.status.includes(value as string),
      render: (text, record) => (
        <Space>
          <Text>{text}</Text>
          {record.missingFiles > 0 && (
            <Text type="danger">
              ({record.missingFiles} fichiers manquants)
            </Text>
          )}
        </Space>
      ),
    },
    {
      title: "Prénom",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName", "Rechercher Prénom"),
    },
    {
      title: "Nom",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName", "Rechercher Nom"),
    },
    {
      title: "Résumé du projet",
      dataIndex: "projectSummary",
      key: "projectSummary",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`/backoffice/dossiers/${record.key}`);
          }}
        >
          {text}
        </Button>
      ),
    },
  ];

  const filteredData = folders.filter((item) => {
    if (statusFilter) {
      return item.status === statusFilter;
    }
    return true;
  });

  return (
    <Container>
      <TableWrapper>
        <ButtonContainer>
          <Button onClick={() => handleReset()}>
            Réinitialiser les filtres
          </Button>
        </ButtonContainer>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ pageSize: 5 }}
          style={{ width: "100%" }}
          scroll={{ x: 800 }}
        />
      </TableWrapper>
    </Container>
  );
};

export default FoldersBackoffice;
