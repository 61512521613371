import React, { useState, useEffect } from "react";
import {
  Collapse,
  Row,
  Col,
  Card as AntCard,
  Space,
  Button,
  Input,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { BACKGROUND_COLOR, MAIN_COLOR } from "../../../utils";
import {
  emailAdminInvalidFiles,
  getFolderById,
  updateAdminFile,
  updateAdminFolderById,
} from "../../../services/RealEstateFolder.service";
import {
  FileData,
  FileStatus,
  REFolderStatus,
} from "../../../types/REFolder.interface";
import { useNavigate } from "react-router-dom";
import { checkApiError } from "../../../apiCheckError";
import { countTotalFiles } from "../../page/FoldersBackoffice";

const { Panel } = Collapse;

interface Counts {
  inReview: number;
  validated: number;
  invalidated: number;
  empty: number;
}

const countFileStatuses = (files: FileData[]): Counts => {
  const counts: Counts = {
    inReview: 0,
    validated: 0,
    invalidated: 0,
    empty: files.length,
  };

  files.forEach((file) => {
    if (file.status === FileStatus.InReview) {
      counts.inReview++;
    } else if (file.status === FileStatus.Validated) {
      counts.validated++;
    } else if (file.status === FileStatus.Invalidated) {
      counts.invalidated++;
    }
  });

  counts.empty =
    files.length - (counts.inReview + counts.validated + counts.invalidated);
  return counts;
};

const renderCounts = (counts: Counts) => (
  <Space style={{ marginLeft: 8 }} size="middle">
    {counts.validated > 0 && (
      <span>
        <CheckCircleOutlined style={{ color: "green" }} /> {counts.validated}
      </span>
    )}
    {counts.inReview > 0 && (
      <span>
        <ExclamationCircleOutlined style={{ color: "orange" }} />{" "}
        {counts.inReview}
      </span>
    )}
    {counts.empty > 0 && (
      <span>
        <LoadingOutlined style={{ color: MAIN_COLOR }} /> {counts.empty}
      </span>
    )}
    {counts.invalidated > 0 && (
      <span>
        <CloseCircleOutlined style={{ color: "red" }} /> {counts.invalidated}
      </span>
    )}
  </Space>
);

const categorizeFiles = (files: FileData[]) => {
  const identityFiles: { [person: string]: FileData[] } = {};
  const incomeFiles: { [person: string]: FileData[] } = {};
  const projectFiles: { [person: string]: FileData[] } = {};

  files.forEach((file) => {
    const person = file.name.match(/person_(\d+)/)?.[1] || "1"; // Default to "1" if no match
    if (file.name.includes("identity")) {
      if (!identityFiles[person]) identityFiles[person] = [];
      identityFiles[person].push(file);
    } else if (file.name.includes("income")) {
      if (!incomeFiles[person]) incomeFiles[person] = [];
      incomeFiles[person].push(file);
    } else if (file.name.includes("project")) {
      if (!projectFiles[person]) projectFiles[person] = [];
      projectFiles[person].push(file);
    }
  });

  return { identityFiles, incomeFiles, projectFiles };
};

interface AccordionProps {
  files: FileData[];
  folderId: string;
}

const AccordionBackoffice: React.FC<AccordionProps> = ({
  files: initialFiles,
  folderId,
}) => {
  const [files, setFiles] = useState(initialFiles);
  const [comment, setComment] = useState("");
  const [fileIdToInvalidate, setFileIdToInvalidate] = useState<string | null>(
    null,
  );
  const [invalidatedFiles, setInvalidatedFiles] = useState<string[]>([]);
  const [allValidated, setAllValidated] = useState(() => {
    return JSON.parse(
      localStorage.getItem(`${folderId}-allValidated`) || "false",
    );
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(
      `${folderId}-allValidated`,
      JSON.stringify(allValidated),
    );
  }, [allValidated, folderId]);

  const handleButtonValidate = async (fileId: string, name: string) => {
    checkApiError(
      (
        await updateAdminFile(folderId, fileId, {
          status: FileStatus.Validated,
        })
      ).respStatus,
    );
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId ? { ...file, status: FileStatus.Validated } : file,
      ),
    );
  };

  const handleButtonInvalidate = async (fileId: string) => {
    setFileIdToInvalidate(fileId);
  };

  const areAllFilesValidated = async () => {
    const result = await getFolderById(folderId);
    checkApiError(result.respStatus);
    return (
      files.every((file) => file.status === FileStatus.Validated) &&
      files.length === countTotalFiles(result.respData)
    );
  };

  const handleConfirmInvalidate = async (fileId: string, name: string) => {
    checkApiError(
      (
        await updateAdminFile(folderId, fileId, {
          status: FileStatus.Invalidated,
          comment,
        })
      ).respStatus,
    );
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === fileId
          ? { ...file, status: FileStatus.Invalidated, comment }
          : file,
      ),
    );
    setInvalidatedFiles((prevInvalidatedFiles) => [
      ...prevInvalidatedFiles,
      fileId,
    ]);
    setFileIdToInvalidate(null);
    setComment("");
  };

  const handleAccordionSubmit = async () => {
    if (await areAllFilesValidated()) {
      checkApiError(
        (
          await updateAdminFolderById(folderId, {
            status: REFolderStatus.Analysing,
          })
        ).respStatus,
      );
      setAllValidated(true);
      navigate(0);
      message.success(
        "Tous les fichiers sont validés et le statut est mis à jour.",
      );
    }

    if (invalidatedFiles.length > 0) {
      checkApiError((await emailAdminInvalidFiles(folderId)).respStatus);
      setInvalidatedFiles([]);
      message.success("Les fichiers invalidés ont été signalés.");
    }
  };

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const renderCardBody = (file: FileData) => {
    if (file.status === FileStatus.Validated && !isEditMode) {
      return null;
    }

    if (
      file.status === FileStatus.Validated &&
      isEditMode &&
      fileIdToInvalidate !== file.id
    ) {
      return (
        <Button
          type="primary"
          danger
          onClick={() => handleButtonInvalidate(file.id)}
          style={{ borderColor: "red" }}
        >
          Invalider
        </Button>
      );
    }

    if (file.id === fileIdToInvalidate) {
      return (
        <div>
          <Input.TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Motif d'invalidation"
            required
          />
          <Button
            type="primary"
            danger
            onClick={() => handleConfirmInvalidate(file.id, file.name)}
            style={{ borderColor: "red", marginTop: "8px" }}
            disabled={!comment.trim()}
          >
            Invalider
          </Button>
        </div>
      );
    }

    if (file.status === FileStatus.Invalidated) {
      return <p>{file.comment}</p>;
    }

    return (
      <div>
        <Button
          type="primary"
          onClick={() => handleButtonValidate(file.id, file.name)}
          style={{ borderColor: "green", marginRight: "8px" }}
        >
          Valider
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => handleButtonInvalidate(file.id)}
          style={{ borderColor: "red" }}
        >
          Invalider
        </Button>
      </div>
    );
  };

  const { identityFiles, incomeFiles, projectFiles } = categorizeFiles(files);

  return (
    <>
      <Collapse accordion>
        <Panel
          header={
            <div>
              Identité
              {renderCounts(
                countFileStatuses(Object.values(identityFiles).flat()),
              )}
            </div>
          }
          key="1"
        >
          {Object.entries(identityFiles).map(([person, files]) => (
            <div key={person}>
              <h3>Personne {person}</h3>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: BACKGROUND_COLOR,
                }}
              >
                <Row gutter={[16, 30]}>
                  {files.map((file) => (
                    <Col key={file.name} xs={24} sm={12} md={8}>
                      <AntCard
                        title={
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              whiteSpace: "normal",
                              fontSize: "1.2em",
                              fontWeight: "bold",
                            }}
                          >
                            {file.name}
                          </a>
                        }
                        bordered={true}
                        style={{
                          borderColor:
                            file.status === FileStatus.Validated
                              ? "green"
                              : file.status === FileStatus.Invalidated
                                ? "red"
                                : undefined,
                          wordWrap: "break-word",
                        }}
                      >
                        <div style={{ whiteSpace: "normal" }}>
                          {renderCardBody(file)}
                        </div>
                      </AntCard>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))}
        </Panel>
        <Panel
          header={
            <div>
              Revenus
              {renderCounts(
                countFileStatuses(Object.values(incomeFiles).flat()),
              )}
            </div>
          }
          key="2"
        >
          {Object.entries(incomeFiles).map(([person, files]) => (
            <div key={person}>
              <h3>Personne {person}</h3>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: BACKGROUND_COLOR,
                }}
              >
                <Row gutter={[16, 30]}>
                  {files.map((file) => (
                    <Col key={file.name} xs={24} sm={12} md={8}>
                      <AntCard
                        title={
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              whiteSpace: "normal",
                              fontSize: "1.2em",
                              fontWeight: "bold",
                            }}
                          >
                            {file.name}
                          </a>
                        }
                        bordered={true}
                        style={{
                          borderColor:
                            file.status === FileStatus.Validated
                              ? "green"
                              : file.status === FileStatus.Invalidated
                                ? "red"
                                : undefined,
                          wordWrap: "break-word",
                        }}
                      >
                        <div style={{ whiteSpace: "normal" }}>
                          {renderCardBody(file)}
                        </div>
                      </AntCard>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))}
        </Panel>
        <Panel
          header={
            <div>
              Profil
              {renderCounts(
                countFileStatuses(Object.values(projectFiles).flat()),
              )}
            </div>
          }
          key="3"
        >
          {Object.entries(projectFiles).map(([person, files]) => (
            <div key={person}>
              <h3>Personne {person}</h3>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: BACKGROUND_COLOR,
                }}
              >
                <Row gutter={[16, 30]}>
                  {files.map((file) => (
                    <Col key={file.name} xs={24} sm={12} md={8}>
                      <AntCard
                        title={
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              whiteSpace: "normal",
                              fontSize: "1.2em",
                              fontWeight: "bold",
                            }}
                          >
                            {file.name}
                          </a>
                        }
                        bordered={true}
                        style={{
                          borderColor:
                            file.status === FileStatus.Validated
                              ? "green"
                              : file.status === FileStatus.Invalidated
                                ? "red"
                                : undefined,
                          wordWrap: "break-word",
                        }}
                      >
                        <div style={{ whiteSpace: "normal" }}>
                          {renderCardBody(file)}
                        </div>
                      </AntCard>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))}
        </Panel>
      </Collapse>
      <div style={{ textAlign: "center", marginTop: "24px" }}>
        <Button
          type="primary"
          onClick={handleAccordionSubmit}
          style={{ marginRight: "8px" }}
        >
          Soumettre
        </Button>
        <Button type="default" onClick={handleEditClick}>
          {isEditMode ? "Annuler" : "Modifier"}
        </Button>
      </div>
    </>
  );
};

export default AccordionBackoffice;
