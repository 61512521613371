import React, { useEffect } from "react";
import {
  Card,
  Form,
  InputNumber,
  Checkbox,
  Button,
  Row,
  Col,
  message,
  Input,
} from "antd";
import { DollarOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { BidProposition } from "../../../types/REFolder.interface";
import { sendProposition } from "../../../services/RealEstateFolder.service";
import { MAIN_COLOR } from "../../../utils";

const CheckboxGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BidForm: React.FC<{
  folderId: string | undefined;
  setBidPropositions: React.Dispatch<React.SetStateAction<BidProposition[]>>;
  bidPropositions: BidProposition[];
  amount: number;
  rate: number;
  durationInYear: number;
  contribution: number;
}> = ({
  folderId,
  setBidPropositions,
  bidPropositions,
  amount,
  rate,
  durationInYear,
  contribution,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      amount,
      rate,
      durationInYear,
      contribution,
    });
  }, [form, amount, rate, durationInYear, contribution]);

  const handleFormSubmit = async (values: BidProposition) => {
    if (
      values.amount < 0 ||
      values.rate < 0 ||
      values.durationInYear < 0 ||
      values.contribution < 0
    ) {
      message.error(
        "Les valeurs des champs ne peuvent pas être inférieures à 0.",
      );
      return;
    }

    const bidProposition: Partial<BidProposition> = {
      amount: values.amount,
      rate: values.rate,
      durationInYear: values.durationInYear,
      contribution: values.contribution,
      domiciliationOfIncomes: values.domiciliationOfIncomes || false,
      domiciliationOfSavings: values.domiciliationOfSavings || false,
      homeInsurance: values.homeInsurance || false,
    };

    if (values.otherConditions) {
      bidProposition.otherConditions = values.otherConditions;
    }

    if (folderId) {
      await sendProposition(folderId, bidProposition as BidProposition);
      message.success("Proposition envoyée avec succès !");
      setBidPropositions([
        ...bidPropositions,
        bidProposition as BidProposition,
      ]);
    }
  };

  return (
    <Card
      title={
        <>
          <DollarOutlined style={{ color: MAIN_COLOR, marginRight: "8px" }} />
          Faire une proposition
        </>
      }
      style={{ width: "100%" }}
    >
      <Form form={form} onFinish={handleFormSubmit}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="amount"
              rules={[
                { required: true, message: "Veuillez entrer le montant" },
              ]}
            >
              <InputNumber
                placeholder="Montant (€)"
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) =>
                  value ? value.replace(/\s/g, "").replace("€", "") : ""
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="rate"
              rules={[{ required: true, message: "Veuillez entrer le taux" }]}
            >
              <InputNumber
                placeholder="Taux (%)"
                style={{ width: "100%" }}
                step={0.01}
                formatter={(value) => `${value}%`}
                parser={(value) => (value ? value.replace("%", "") : "")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="durationInYear"
              rules={[{ required: true, message: "Veuillez entrer la durée" }]}
            >
              <InputNumber
                placeholder="Durée (ans)"
                style={{ width: "100%" }}
                formatter={(value) => `${value} ans`}
                parser={(value) => (value ? value.replace(" ans", "") : "")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="contribution"
              rules={[{ required: true, message: "Veuillez entrer l'apport" }]}
            >
              <InputNumber
                placeholder="Apport (€)"
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) =>
                  value ? value.replace(/\s/g, "").replace("€", "") : ""
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <CheckboxGroup>
          <Form.Item name="domiciliationOfIncomes" valuePropName="checked">
            <Checkbox>Domiciliation des revenus</Checkbox>
          </Form.Item>
          <Form.Item name="domiciliationOfSavings" valuePropName="checked">
            <Checkbox>Domiciliation de l'épargne</Checkbox>
          </Form.Item>
          <Form.Item name="homeInsurance" valuePropName="checked">
            <Checkbox>Assurance habitation</Checkbox>
          </Form.Item>
        </CheckboxGroup>
        <Form.Item name="otherConditions">
          <Input.TextArea placeholder="Autres conditions (si plusieurs, merci de les séparer d'une virgule)" />
        </Form.Item>
        <Form.Item>
          <ButtonWrapper>
            <Button type="primary" htmlType="submit">
              Envoyer la proposition
            </Button>
          </ButtonWrapper>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default BidForm;
