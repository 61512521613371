import React, { useState } from "react";
import { Steps } from "antd";
import styled from "styled-components";
import { MAIN_COLOR } from "../../utils";

interface StepperProps {
  currentStep: number;
  onStepClick: (stepIndex: number) => void;
}

const StyledStepTitle = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? MAIN_COLOR : "inherit")};
`;

const Stepper: React.FC<StepperProps> = ({ currentStep, onStepClick }) => {
  const [highlightedStep, setHighlightedStep] = useState<number | null>(null);

  const handleStepClick = (stepIndex: number) => {
    setHighlightedStep(stepIndex);
    onStepClick(stepIndex);
  };

  const getStepTitle = (index: number) => {
    if (index < currentStep) return "Terminé";
    if (index === currentStep) return "En Cours";
    return "En attente";
  };

  const steps = [
    { title: getStepTitle(0), description: "Ajout des informations" },
    { title: getStepTitle(1), description: "Ajout des documents" },
    { title: getStepTitle(2), description: "Signature du mandat" },
    { title: getStepTitle(3), description: "Analyse par le courtier" },
    { title: getStepTitle(4), description: "Vérification des documents" },
  ];

  return (
    <Steps
      type="navigation"
      current={highlightedStep !== null ? highlightedStep : currentStep}
      onChange={handleStepClick}
    >
      {steps.map((step, index) => (
        <Steps.Step
          key={index}
          title={
            <StyledStepTitle isActive={index === currentStep}>
              {step.title}
            </StyledStepTitle>
          }
          description={
            <StyledStepTitle isActive={index === currentStep}>
              {step.description}
            </StyledStepTitle>
          }
          status={
            currentStep > index
              ? "finish"
              : currentStep === index
                ? "process"
                : "wait"
          }
        />
      ))}
    </Steps>
  );
};

export default Stepper;
