import { Layout } from "antd";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ThemeProvider from "./ThemeProvider";
import AppFooter from "./component/footer/Footer";
import Contact from "./page/Contact";
import HomePage from "./page/HomePage";
import MyFiles from "./page/MyFolders";
import FileUpload from "./page/FileUpload";
import Authentification from "./page/Authentication";
import Validation from "./page/EmailValidation";
import { UserProvider } from "./context/UserContext";
import { AuthProvider } from "./authentification/AuthProvider";
import Profil from "./page/Profil";
import ProtectedRoute from "./authentification/ProtectedRoute";
import ForgotPassword from "./page/ForgotPassword";
import ResetPasswordForm from "./page/ResetPassword";
import NotFound from "./page/NotFound";
import ProtectedBackofficeRoute from "./backoffice/ProtectedBackofficeRoute";
import HomepageBackoffice from "./backoffice/page/HomepageBackoffice";
import AuthenticationBackoffice from "./backoffice/page/AuthenticationBackoffice";
import ProfileBackoffice from "./backoffice/page/ProfileBackoffice";
import FoldersBackoffice from "./backoffice/page/FoldersBackoffice";
import FolderDetailsBackoffice from "./backoffice/page/FolderDetailsBackoffice";
import HomepageBank from "./bank/page/HomepageBank";
import ProtectedBankRoute from "./bank/ProtectedBankRoute";
import MyFoldersBank from "./bank/page/MyFoldersBank";
import FolderDetailsBank from "./bank/page/FolderDetailsBank";
import StatsBank from "./bank/page/StatsBank";
import AuthenticationBank from "./bank/page/AuthenticationBank";
import Bids from "./page/Bids";
import ProfilBank from "./bank/page/ProfileBank";
import ParamBank from "./bank/page/ParamBank";

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <UserProvider>
          <Router>
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/404error" element={<NotFound />} />
                <Route
                  path="/authentification"
                  element={<Authentification />}
                />
                <Route
                  path="/inscription/:id/validation"
                  element={<Validation />}
                />
                <Route
                  path="/recuperation-mot-de-passe"
                  element={<ForgotPassword />}
                />

                <Route
                  path="/recuperation-mot-de-passe/validation"
                  element={<ResetPasswordForm />}
                />

                <Route element={<ProtectedRoute />}>
                  <Route path="/mes-dossiers/:id" element={<FileUpload />} />
                  <Route path="/mes-dossiers" element={<MyFiles />} />
                  <Route path="/profil" element={<Profil />} />
                  <Route path="/mes-dossiers/:id/enchere" element={<Bids />} />
                </Route>

                <Route element={<ProtectedBackofficeRoute />}>
                  <Route path="/backoffice" element={<HomepageBackoffice />} />
                  <Route
                    path="/backoffice/profil"
                    element={<ProfileBackoffice />}
                  />
                  <Route
                    path="/backoffice/dossiers"
                    element={<FoldersBackoffice />}
                  />
                  <Route
                    path="/backoffice/dossiers/:id"
                    element={<FolderDetailsBackoffice />}
                  />
                </Route>
                <Route
                  path="/backoffice/authentification"
                  element={<AuthenticationBackoffice />}
                />

                <Route element={<ProtectedBankRoute />}>
                  <Route path="/banque" element={<HomepageBank />} />
                  <Route
                    path="/banque/mes-dossiers"
                    element={<MyFoldersBank />}
                  />
                  <Route
                    path="/banque/mes-dossiers/:id"
                    element={<FolderDetailsBank />}
                  />
                  <Route path="/banque/statistiques" element={<StatsBank />} />
                  <Route path="/banque/mon-profil" element={<ProfilBank />} />
                  <Route path="/banque/parametres" element={<ParamBank />} />
                </Route>

                <Route path="*" element={<NotFound />} />

                <Route
                  path="/banque/authentification"
                  element={<AuthenticationBank />}
                />
              </Routes>
              <AppFooter />
            </Layout>
          </Router>
        </UserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
