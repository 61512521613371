import { Tabs, theme } from "antd";
import { useState } from "react";
import SignupCard from "../component/signup/SignupCard";
import LoginCard from "../component/login/LoginCard";
import logo from "../assets/reeady.svg";

type LoginType = "signin" | "register";

const Authentication: React.FC = () => {
  const { token } = theme.useToken();
  const [loginType, setLoginType] = useState<LoginType>("signin");

  const tabItems = [
    {
      key: "signin",
      label: "Connexion",
      children: (
        <div style={{ minHeight: "100vh" }}>
          <LoginCard />
        </div>
      ),
    },
    {
      key: "register",
      label: "Créer un compte",
      children: (
        <div style={{ minHeight: "100vh" }}>
          <SignupCard />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div style={{ textAlign: "center", padding: "24px 0" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ height: "80px", marginBottom: "16px" }}
        />
        <h3
          style={{
            marginBottom: "24px",
            color: token.colorTextSecondary,
          }}
        >
          Reprenez le contrôle de vos crédits bancaires
        </h3>
      </div>
      <Tabs
        centered
        activeKey={loginType}
        onChange={(activeKey) => setLoginType(activeKey as LoginType)}
        items={tabItems}
      />
    </div>
  );
};

export default Authentication;
