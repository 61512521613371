import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Input,
  Form,
  Typography,
  Card,
  Space,
  Divider,
  Spin,
  message,
  Select,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  logout,
  updateUserInfos,
  updateEmail,
  getUserById,
  updatePassword,
} from "../services/users.service";
import { useUser } from "../context/UserContext";
import { PartnerBanks } from "../types";
import {
  UserOutlined,
  MailOutlined,
  BankOutlined,
  LogoutOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { MAIN_COLOR } from "../utils";
import { checkApiError } from "../apiCheckError";

const { Title, Text } = Typography;
const { Option } = Select;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;

  @media (max-width: 768px) {
    padding: 0 10px;
    height: auto;
  }
`;

const InfoText = styled(Text)`
  font-size: 18px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const StyledTitle = styled(Title)`
  font-size: 24px;
`;

const StyledInput = styled(Input)`
  font-size: 18px;
`;

const StyledInputPassword = styled(Input.Password)`
  font-size: 18px;
`;

const StyledSelect = styled(Select)`
  font-size: 18px;

  .ant-select-selector {
    font-size: 18px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-form-item-control-input {
    font-size: 18px;
  }
`;

const Profil: React.FC = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [loading, setLoading] = useState(!user);
  const [formInfo] = Form.useForm();
  const [formEmail] = Form.useForm();
  const [formPassword] = Form.useForm();

  useEffect(() => {
    const fetchUser = async () => {
      if (user && !loading) return;
      if (user) {
        const userResponse = await getUserById(user?.id);
        checkApiError(userResponse.respStatus);
        const userData = userResponse.respData;
        setUser(userData);
        setLoading(false);
      }
    };

    fetchUser();
  }, [user?.id, setUser, user, loading]);

  const handleLogout = async () => {
    checkApiError((await logout()).respStatus);
    navigate("/authentification");
    message.success("Déconnexion réussie.");
  };

  const handleEditInfo = () => {
    setIsEditingInfo(true);
    formInfo.setFieldsValue({
      firstname: user?.firstname,
      lastname: user?.lastname,
      currentBank: user?.currentBank,
    });
  };

  const handleEditEmail = () => {
    setIsEditingEmail(true);
    formEmail.setFieldsValue({
      email: user?.email,
    });
  };

  const handleEditPassword = () => {
    setIsEditingPassword(true);
    formPassword.resetFields();
  };

  const handleSaveInfo = async (values: {
    firstname: string;
    lastname: string;
    currentBank: string;
  }) => {
    if (user) {
      checkApiError(
        (
          await updateUserInfos({
            id: user.id,
            firstname: values.firstname,
            lastname: values.lastname,
            email: user.email,
            currentBank: values.currentBank,
            admin_role: user.admin_role,
          })
        ).respStatus,
      );
      setUser({
        ...user,
        firstname: values.firstname,
        lastname: values.lastname,
        currentBank: values.currentBank,
      });
      setIsEditingInfo(false);
      message.success("Informations mises à jour avec succès.");
    }
  };

  const handleSaveEmail = async (values: { email: string }) => {
    if (user) {
      checkApiError((await updateEmail(values.email)).respStatus);
      navigate(`/inscription/${user.id}/validation`);
      setIsEditingEmail(false);
      message.info(
        "Merci d'entrer le code de validation dans le mail que nous vous avons envoyé à votre nouvelle adresse.",
      );
    }
  };

  const handleSavePassword = async (values: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error("Les mots de passe ne correspondent pas.");
      return;
    }

    checkApiError(
      (await updatePassword(values.currentPassword, values.newPassword))
        .respStatus,
    );
    setIsEditingPassword(false);
    message.success("Mot de passe mis à jour avec succès.");
  };

  if (loading) {
    return (
      <Container>
        <Spin size="large" />
      </Container>
    );
  }

  return (
    <Container>
      <Card
        style={{
          width: "100%",
          maxWidth: 500,
          padding: 24,
          borderRadius: 8,
          border: `1px solid ${MAIN_COLOR}`,
        }}
      >
        {!isEditingInfo && !isEditingEmail && !isEditingPassword ? (
          <>
            <StyledTitle level={4}>Profil</StyledTitle>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <InfoText>
                  <UserOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <BoldText>Prénom : </BoldText> {user?.firstname}
                </InfoText>
              </Col>
              <Col xs={24}>
                <InfoText>
                  <UserOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <BoldText>Nom : </BoldText> {user?.lastname}
                </InfoText>
              </Col>
              <Col xs={24}>
                <InfoText>
                  <MailOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <BoldText>Email : </BoldText> {user?.email}
                </InfoText>
              </Col>
              <Col xs={24}>
                <InfoText>
                  <BankOutlined style={{ color: MAIN_COLOR, marginRight: 8 }} />
                  <BoldText>Banque : </BoldText> {user?.currentBank}
                </InfoText>
              </Col>
            </Row>
            <Divider />
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button
                type="primary"
                onClick={handleEditInfo}
                block
                icon={<EditOutlined />}
              >
                Modifier mes informations
              </Button>
              <Button
                type="primary"
                onClick={handleEditEmail}
                block
                icon={<EditOutlined />}
              >
                Modifier mon email
              </Button>
              <Button
                type="primary"
                onClick={handleEditPassword}
                block
                icon={<EditOutlined />}
              >
                Modifier mon mot de passe
              </Button>
              <Button
                type="primary"
                onClick={handleLogout}
                block
                icon={<LogoutOutlined />}
              >
                Déconnexion
              </Button>
            </Space>
          </>
        ) : isEditingInfo ? (
          <Form form={formInfo} onFinish={handleSaveInfo} layout="vertical">
            <StyledTitle level={4}>Modifier mes informations</StyledTitle>
            <StyledFormItem
              label="Prénom"
              name="firstname"
              rules={[{ required: true, message: "Le prénom est obligatoire" }]}
            >
              <StyledInput placeholder="Prénom" />
            </StyledFormItem>
            <StyledFormItem
              label="Nom"
              name="lastname"
              rules={[{ required: true, message: "Le nom est obligatoire" }]}
            >
              <StyledInput placeholder="Nom" />
            </StyledFormItem>
            <StyledFormItem
              label="Banque"
              name="currentBank"
              rules={[{ required: true, message: "La banque est obligatoire" }]}
            >
              <StyledSelect placeholder="Sélectionnez votre banque">
                {Object.values(PartnerBanks).map((bank) => (
                  <Option key={bank} value={bank}>
                    {bank}
                  </Option>
                ))}
              </StyledSelect>
            </StyledFormItem>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Sauvegarder
                </Button>
                <Button onClick={() => setIsEditingInfo(false)}>Annuler</Button>
              </Space>
            </Form.Item>
          </Form>
        ) : isEditingEmail ? (
          <Form form={formEmail} onFinish={handleSaveEmail} layout="vertical">
            <StyledTitle level={4}>Modifier mon email</StyledTitle>
            <StyledFormItem
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "L'adresse email est obligatoire",
                },
                {
                  type: "email",
                  message: "L'adresse email n'est pas valide",
                },
              ]}
            >
              <StyledInput placeholder="Email" />
            </StyledFormItem>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Sauvegarder
                </Button>
                <Button onClick={() => setIsEditingEmail(false)}>
                  Annuler
                </Button>
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <Form
            form={formPassword}
            onFinish={handleSavePassword}
            layout="vertical"
          >
            <StyledTitle level={4}>Modifier mon mot de passe</StyledTitle>
            <StyledFormItem
              label="Mot de passe actuel"
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Le mot de passe actuel est obligatoire",
                },
              ]}
            >
              <StyledInputPassword placeholder="Mot de passe actuel" />
            </StyledFormItem>
            <StyledFormItem
              label="Nouveau mot de passe"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Le nouveau mot de passe est obligatoire",
                },
              ]}
            >
              <StyledInputPassword placeholder="Nouveau mot de passe" />
            </StyledFormItem>
            <StyledFormItem
              label="Confirmer le nouveau mot de passe"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "La confirmation du mot de passe est obligatoire",
                },
              ]}
            >
              <StyledInputPassword placeholder="Confirmer le nouveau mot de passe" />
            </StyledFormItem>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Sauvegarder
                </Button>
                <Button onClick={() => setIsEditingPassword(false)}>
                  Annuler
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </Card>
    </Container>
  );
};

export default Profil;
