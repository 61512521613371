import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { loginForm } from "../../../services/users.service";
import { useUser } from "../../../context/UserContext";
import { BACKGROUND_COLOR } from "../../../utils";
import { useAuth } from "../../../authentification/auth.hook";
import { checkApiError } from "../../../apiCheckError";

type LoginFormType = {
  email: string;
  password: string;
  autoLogin: boolean;
};

export default function LoginBackofficeCard() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginFormType>();
  const { setUser } = useUser();
  const auth = useAuth();

  const onSubmit = async (data: LoginFormType) => {
    setIsSubmitting(true);
    setLoginError(null);
    const response = await loginForm({
      email: data.email,
      password: data.password,
    });
    checkApiError(response.respStatus);
    setUser(response.respData.user);
    auth.login(response.respData.token, response.respData.user);

    navigate(`/backoffice`);

    setIsSubmitting(false);
  };

  return (
    <div
      style={{
        backgroundColor: BACKGROUND_COLOR,
        padding: "24px",
        borderRadius: "8px",
        maxWidth: "400px",
        margin: "auto",
      }}
    >
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        {loginError && (
          <div style={{ color: "red", marginBottom: "24px" }}>{loginError}</div>
        )}
        <Form.Item
          label="Adresse email"
          validateStatus={errors.email ? "error" : ""}
          help={errors.email ? errors.email.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                size="large"
                prefix={<UserOutlined />}
                placeholder="Rentrez votre adresse email"
              />
            )}
            rules={{
              required: "L'adresse email est obligatoire",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "L'adresse email n'est pas valide",
              },
            }}
          />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          validateStatus={errors.password ? "error" : ""}
          help={errors.password ? errors.password.message : ""}
          style={{ marginBottom: "24px" }}
        >
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input.Password
                {...field}
                size="large"
                prefix={<LockOutlined />}
                placeholder="Rentrez votre mot de passe"
              />
            )}
            rules={{ required: "Le mot de passe est obligatoire" }}
          />
        </Form.Item>
        <Link to="/recuperation-mot-de-passe" style={{ float: "right" }}>
          J'ai oublié mon mot de passe
        </Link>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isSubmitting}
            style={{ marginTop: "24px" }}
          >
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
