import { ConfigProvider, theme } from "antd";
import React from "react";
import { createGlobalStyle } from "styled-components";
import { BACKGROUND_COLOR, MAIN_COLOR, REAL_BACKGROUND_COLOR } from "./utils";

const GlobalStyle = createGlobalStyle`
  .ant-btn-primary {
    color: ${REAL_BACKGROUND_COLOR};
  }

  .ant-btn-primary:hover {
    color: ${BACKGROUND_COLOR} !important;
  }
`;

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        colorPrimary: MAIN_COLOR,
        colorBgBase: REAL_BACKGROUND_COLOR,
        colorLink: MAIN_COLOR,
        fontSize: 14,
      },
    }}
  >
    <GlobalStyle />
    {children}
  </ConfigProvider>
);

export default ThemeProvider;
