import React, { useState, useEffect } from "react";
import { Select, Button, Input, Space, Card, message } from "antd";
import { getBankUsers, newBankAccount } from "../../../services/users.service";
import { UserBankType } from "../../../types";
import { sendFolderToBanks } from "../../../services/RealEstateFolder.service";
import { useParams } from "react-router-dom";
import { checkApiError } from "../../../apiCheckError";

const { Option } = Select;

const BankMail: React.FC = () => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [newItem, setNewItem] = useState<string>("");
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchBankUsers = async () => {
      try {
        const response = await getBankUsers();
        checkApiError(response.respStatus);
        const users: UserBankType[] = response.respData;
        const emails = users.map((user) => user.email);
        setOptions(emails);
      } catch (error) {
        message.error("Erreur lors de la récupération des utilisateurs");
      }
    };

    fetchBankUsers();
  }, []);

  const handleChange = (value: string[]) => {
    setSelectedItems(value);
  };

  const addItem = async () => {
    if (newItem && !options.includes(newItem)) {
      try {
        checkApiError((await newBankAccount(newItem)).respStatus);
        setOptions([...options, newItem]);
        setNewItem("");
        message.success("Nouveau compte bancaire ajouté avec succès");
      } catch (error) {
        message.error("Erreur lors de l'ajout du nouveau compte bancaire");
      }
    } else {
      message.error("L'adresse email existe déjà");
    }
  };

  const handleSubmit = async () => {
    if (id) {
      try {
        checkApiError((await sendFolderToBanks(id, selectedItems)).respStatus);
        message.success("Dossier envoyé avec succès");
      } catch (error) {
        message.error("Erreur lors de l'envoi du dossier");
      }
    }
  };

  return (
    <Card
      title="Choisissez à qui envoyer le dossier"
      style={{ margin: "24px 0" }}
    >
      <Select
        mode="multiple"
        style={{ width: "100%", marginBottom: "16px" }}
        placeholder="Sélectionner les banques"
        value={selectedItems}
        onChange={handleChange}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Space style={{ padding: "8px", display: "flex" }}>
              <Input
                placeholder="Veuillez entrer un mail"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
              />
              <Button type="text" onClick={addItem}>
                + Ajouter un mail
              </Button>
            </Space>
          </>
        )}
      >
        {options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <Button type="primary" onClick={handleSubmit}>
        Valider
      </Button>
    </Card>
  );
};

export default BankMail;
