import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const ProfileBackoffice: React.FC = () => (
  <>
    <Title>Page du profil</Title>
  </>
);

export default ProfileBackoffice;
